<div class="shopping-center-details-page-container container">
  <mtn-key-value-with-placeholder key="Name"
                                  [value]="shoppingCenter.name"></mtn-key-value-with-placeholder>
  <mtn-key-value-with-placeholder key="Owner"
                                  [value]="shoppingCenter.ownerCompany?.name"></mtn-key-value-with-placeholder>
  <mtn-key-value key="Type" [whatIsThisText]="definitionShoppingCenterType?.definition?.description">
    <mtn-definition-view #shoppingCenterType [definitionType]="DefinitionType.SHOPPING_CENTER_TYPE"
                         [systemName]="shoppingCenter.type"
                         *ngIf="shoppingCenter.type; else notAvailable"></mtn-definition-view>
  </mtn-key-value>
  <mtn-key-value-with-placeholder key="GLA" [value]="shoppingCenter.grossLeasableArea?.toLocaleString()"
                                  [whatIsThisText]="Dictionary.tooltipGrossLeasableArea"></mtn-key-value-with-placeholder>
  <mtn-key-value key="Website URL">
    <a [href]="shoppingCenter.websiteUrl" target="_blank" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px"
       *ngIf="shoppingCenter.websiteUrl; else notAvailable">
      <span>Click to Visit</span>
      <fa-icon icon="external-link-square-alt"></fa-icon>
    </a>
  </mtn-key-value>
</div>

<ng-template #notAvailable>
  <span class="color-muted">Not Available</span>
</ng-template>
