import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { StoreVolume } from '../volume/store-volume';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app-state';
import { BaseComponent } from '../../base-component';
import { selectUserState, UserState } from '../../../auth/user-state';
import { filter } from 'rxjs/operators';
import { SalesVolumeDisplayType } from '../../user-preferences/sales-volume-display-type.enum';
import { UserPreferences } from '../../user-preferences/user-preferences';
import { formatAsCurrency, formatNumericalShorthand } from '../../util/string-utils';

@Component({
  selector: 'mtn-sales-volume-view',
  templateUrl: './sales-volume-view.component.html',
  styleUrls: ['./sales-volume-view.component.scss']
})
export class SalesVolumeViewComponent extends BaseComponent implements OnChanges, OnInit {

  public static readonly SUFFIX_ANNUAL = 'Annually';
  public static readonly SUFFIX_WEEKLY = 'Weekly';

  @Input()
  includeSuffix = true;
  @Input()
  salesVolumeDisplayMode: SalesVolumeDisplayType;
  @Input()
  shorthand = false;
  @Input()
  volume: StoreVolume;

  suffix: string;
  value: string;

  private preferences: UserPreferences;

  constructor(private store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    if (!this.salesVolumeDisplayMode) {
      this.subscribeToAuthState();
    }
  }

  ngOnChanges(): void {
    this.initVolume();
  }

  private initVolume(): void {
    if (this.volume && (this.preferences || this.salesVolumeDisplayMode)) {
      const displayMode = this.salesVolumeDisplayMode || this.preferences.salesVolumeDisplayMode;
      this.suffix = SalesVolumeViewComponent.SUFFIX_WEEKLY;
      let volumeAmount = this.volume.total;

      if (displayMode === SalesVolumeDisplayType.ANNUAL) {
        this.suffix = SalesVolumeViewComponent.SUFFIX_ANNUAL;
      }

      if (this.shorthand) {
        this.value = `$${formatNumericalShorthand(volumeAmount)}`;
      } else {
        this.value = `${formatAsCurrency(volumeAmount, false)}`;
      }
    } else {
      this.value = null;
    }
  }

  private subscribeToAuthState(): void {
    this.addSubscription(
      selectUserState(this.store)
        .pipe(filter((state: UserState) => !!state.userPreferences))
        .subscribe((state: UserState) => {
          this.preferences = state.userPreferences;
          this.initVolume();
        })
    );
  }

}
