import { Wizard } from '../../../../core/wizard/wizard';
import { DemographicDetailsPageComponent } from './demographic-details-page/demographic-details-page.component';
import { FeatureCollection, Store } from '../../../../core/models';
import { DemographicDataView } from '../../../../core/depot/demographic-data-view';

export class DemographicDetailsWizard extends Wizard<DemographicDetailsWizardModel> {
  key = 'demographic-details';
  pageTypeMap = {
    'demographic-details': DemographicDetailsPageComponent
  };
  startingPageKey = 'demographic-details';

  constructor() {
    super();
    this.width = 405;
  }
}

export interface DemographicDetailsWizardModel {
  demographics: DemographicDataView;
  marketArea?: FeatureCollection;
  store: Store;
}
