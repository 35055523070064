<div class="public-landing-page-container container" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="80px">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="30px" fxLayoutAlign="space-between center"
       class="announcement-container">
    <div>
      <img alt="Header"
           src="https://res.cloudinary.com/mtn-retail-advisors/image/upload/v1668204350/Creditntell_logo_2022.jpg"/>
    </div>
    <div class="announcement">
      We are now officially part of Creditntell, a consulting and data analytics firm providing independent,
      data-informed location and financial intelligence for the retail sector.
    </div>
    <div class="learn-more-button">
      <a style="color:white" href="http://www.creditntell.com/" target="_blank">
        LEARN MORE
      </a>
    </div>
  </div>
  <mtn-sign-up-sign-in-panel></mtn-sign-up-sign-in-panel>
  <mtn-coverage-map></mtn-coverage-map>
</div>

