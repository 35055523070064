import { Component } from '@angular/core';
import { WizardPageComponent } from '../../../../../../core/wizard/wizard-page-component';
import { SuspendLicenseWizard } from '../suspend-license-wizard';
import { Observable } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LicenseService } from '../../../../../../core/license/license.service';
import { ToastService } from '../../../../../../core/toast/toast.service';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'mtn-suspend-license-page',
  templateUrl: './suspend-license-page.component.html',
  styleUrls: ['./suspend-license-page.component.scss']
})
export class SuspendLicensePageComponent extends WizardPageComponent<SuspendLicenseWizard> {

  key = 'suspend-license';

  constructor(private licenseService: LicenseService,
              private toaster: ToastService) {
    super();
    this.title = 'Suspend License';
    this.closeButtonText = 'Cancel';
    this.nextButtonText = 'Submit';
  }

  onLoad(): Observable<any> {
    this.initForm();
    return super.onLoad();
  }

  onNext(): Observable<string> {
    const formValue = this.form.getRawValue();

    const request = _.cloneDeep(this.wizard.model.company.license);
    request.suspendedDate = new Date();
    request.suspendedReason = formValue.suspendedReason;
    request.suspendedComment = formValue.suspendedComment;

    return this.licenseService.updateOne(request)
      .pipe(map(() => {
        this.wizard.model.isUpdated = true;
        this.toaster.info('Successfully suspended license');
        return null;
      }));
  }

  private initForm(): void {
    this.form = new FormGroup({
      suspendedComment: new FormControl(null, [Validators.maxLength(500)]),
      suspendedReason: new FormControl(null, [Validators.required])
    });
  }

}
