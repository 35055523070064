import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { map } from 'rxjs/operators';
import { getResolvedUrl } from '../../core/util/route-utils';

@Injectable({
  providedIn: 'root'
})
export class IsAuthenticatedGuard implements CanActivate {

  constructor(protected authenticationService: AuthenticationService,
              protected router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.authenticationService.isAuthenticated()
      .pipe(map((result: boolean) => {
        if (result) {
          return result;
        } else {
          return this.routeToPublicPage(next);
        }
      }));
  }

  protected routeToPublicPage(next: ActivatedRouteSnapshot): UrlTree {
    this.authenticationService.setRedirectUrl(getResolvedUrl(next));
    return this.router.parseUrl('');
  }

}
