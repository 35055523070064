import { Component } from '@angular/core';
import { WizardPageComponent } from '../../../wizard/wizard-page-component';
import { UpgradeLicenseWizard } from '../upgrade-license-wizard';

@Component({
  selector: 'mtn-upgrade-license-page',
  templateUrl: './upgrade-license-page.component.html',
  styleUrls: ['./upgrade-license-page.component.scss']
})
export class UpgradeLicensePageComponent extends WizardPageComponent<UpgradeLicenseWizard> {

  key = 'upgrade-license';

  constructor() {
    super();
    this.title = 'Contact MTN to Upgrade';
    this.closeButtonText = 'Close';
  }

}
