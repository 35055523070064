import { Directive, HostListener, Input } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Directive({
  selector: '[mtnFormErrorHighlighter]'
})
export class FormErrorHighlighterDirective {

  @Input()
  mtnFormErrorHighlighter: FormGroup;

  constructor() {
  }

  @HostListener('mouseenter') onHover() {
    if (this.mtnFormErrorHighlighter) {
      this.touchFormControls(this.mtnFormErrorHighlighter);
    }
  }

  private touchFormControls(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      let control: AbstractControl = formGroup.get(key);
      control.markAsTouched();
      control.markAsDirty();

      if (control instanceof FormGroup) {
        this.touchFormControls(control);
      }
    });
  }
}
