<div class="filter-toolbar-container container" *ngIf="toolbarState">
  <div class="inner-container" fxLayout="row" fxLayoutGap="10px">

    <mtn-google-search-assembly [map]="map"></mtn-google-search-assembly>

    <mat-divider vertical="true" style="height: 40px;"></mat-divider>

    <mtn-map-layers-assembly [map]="map" [toolbarState]="toolbarState"></mtn-map-layers-assembly>

    <div class="store-filter-toolbar" fxLayout="row" fxLayoutGap="10px" *ngIf="toolbarState.isStoreLayerEnabled">
      <mtn-sales-filters-assembly [map]="map" [toolbarState]="toolbarState"></mtn-sales-filters-assembly>
      <mtn-area-filters-assembly [map]="map" [toolbarState]="toolbarState"></mtn-area-filters-assembly>
      <mtn-banner-filter-assembly [map]="map" [toolbarState]="toolbarState"></mtn-banner-filter-assembly>
      <mtn-fit-filter-assembly [map]="map" [toolbarState]="toolbarState"></mtn-fit-filter-assembly>
      <mtn-status-filter-assembly [map]="map" [toolbarState]="toolbarState"></mtn-status-filter-assembly>
      <mtn-collection-filter-assembly [map]="map" [toolbarState]="toolbarState"></mtn-collection-filter-assembly>
    </div>

    <mtn-map-toolbar-button icon="times" color="red" iconColor="red" matTooltip="Clear All Filters"
                            [disabled]="!toolbarState.filterCount"
                            mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                            [analyticsEventParameters]="{'target': 'map-clear-filters-button'}"
                            *ngIf="!!map.options.featureType" (onClick)="clearAllFilters()"></mtn-map-toolbar-button>

    <div fxFlex></div>

    <div class="saved-search-container container" [formGroup]="form">
      <mat-form-field class="default-field-width">
        <mat-select formControlName="savedSearch" placeholder="Saved Search" #select>
          <mat-select-trigger>{{select.value?.name || 'None'}}</mat-select-trigger>
          <mat-option class="color-muted" (onSelectionChange)="handleSavedSearchSelected($event)">None</mat-option>
          <mat-option *ngFor="let option of savedSearches" [value]="option"
                      mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.USE_SAVED_SEARCH"
                      (onSelectionChange)="handleSavedSearchSelected($event)">
            <div class="select-option" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px"
                 style="line-height: 16px; max-width: 280px;">
              <div fxLayout="column" style="max-width: 244px">
                <small class="text-ellipsis">{{option.name}}</small>
                <small class="text-ellipsis color-muted">{{option.description}}</small>
              </div>
              <div fxFlex></div>
              <div class="container" mtnClickTrap [preventDefault]="false">
                <fa-icon class="clickable" icon="trash-alt" matTooltip="Delete" matTooltipPosition="left"
                         (click)="openConfirmDeleteWizard(option)"></fa-icon>
              </div>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mtn-spinner [size]="SpinnerSize.EXTRA_SMALL" [on]="!isSavedSearchesLoaded"></mtn-spinner>
    </div>

    <div class="container">
      <mtn-map-toolbar-button icon="save" matTooltip="Save Changes"
                              [disabled]="!form.get('savedSearch').value?.uuid || !isUnsavedChangesPresent"
                              mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                              [analyticsEventParameters]="{'target': 'update-saved-search'}"
                              (onClick)="saveFilterGroupChanges()"></mtn-map-toolbar-button>
      <mtn-spinner [size]="SpinnerSize.EXTRA_SMALL" [on]="isSaving"></mtn-spinner>
    </div>

    <mtn-map-toolbar-button icon="save" [showEllipsis]="true" matTooltip="Save Filters..."
                            mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                            [analyticsEventParameters]="{'target': 'create-saved-search'}"
                            (onClick)="openNewSavedSearchWizard()"></mtn-map-toolbar-button>
  </div>
</div>
