import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { UserProfile } from '../../../user-profile/user-profile';
import { UserProfileService } from '../../../user-profile/user-profile.service';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { Pageable } from '../../../service/pageable';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import * as _ from 'lodash';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'mtn-user-autocomplete-field',
  templateUrl: './user-autocomplete-field.component.html',
  styleUrls: ['./user-autocomplete-field.component.scss']
})
export class UserAutocompleteFieldComponent implements OnInit {

  @Output()
  onSelected = new EventEmitter<UserProfile>();

  @Input()
  excludeUuids: string[] = [];
  @Input()
  form: FormGroup;
  @Input()
  controlName: string = 'user';
  @Input()
  isRequired = false;
  @Input()
  placeholder: string = 'User';

  search$: Observable<UserProfile[]>;

  constructor(private userProfileService: UserProfileService) {
  }

  ngOnInit(): void {
    this.search$ = this.form.get(this.controlName).valueChanges
      .pipe(startWith(''),
        debounceTime(300),
        switchMap((value: any) => {
          if (value && !(value instanceof UserProfile)) {
            return this.search(value);
          } else {
            return of(null);
          }
        }));
  }

  getDisplayText(userProfile: UserProfile): string {
    return userProfile && userProfile.getDisplayName();
  }

  handleSelection(event: MatAutocompleteSelectedEvent): void {
    this.onSelected.emit(event.option.value);
  }

  private search(q: string): Observable<UserProfile[]> {
    const params = new HttpParams()
      .set('q', q)
      .set('page', '0')
      .set('size', '25');

    return this.userProfileService.findAll(params)
      .pipe(map((page: Pageable<UserProfile>) => {
        return _.reject(page.content, (userProfile: UserProfile) => {
          return _.includes(this.excludeUuids, userProfile.uuid);
        })
      }));
  }

}
