import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FilterToolbarState } from '../filter-toolbar-state';
import { FormControl, FormGroup } from '@angular/forms';
import { MtnMap } from '../../mtn-map';
import { BaseComponent } from '../../../core/base-component';
import { FilterGroup } from '../../../core/federation/filter/filter-group';
import { FeatureType } from '../../../core/federation/feature/feature-type.enum';
import { pairwise, startWith } from 'rxjs/operators';
import { MtnMapOptions } from '../../mtn-map-options';

@Component({
  selector: 'mtn-map-layers-assembly',
  templateUrl: './map-layers-assembly.component.html',
  styleUrls: ['./map-layers-assembly.component.scss']
})
export class MapLayersAssemblyComponent extends BaseComponent implements OnChanges, OnInit {

  @Input()
  map: MtnMap;
  @Input()
  toolbarState: FilterToolbarState;

  form = new FormGroup({
    isStoreLayerEnabled: new FormControl(false),
    isPointsOfInterestLayerEnabled: new FormControl(false)
  });

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.updateForm();
    this.subscribeToFormChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.toolbarState) {
      this.updateForm();
    }
  }

  clearAll(): void {
    this.form.setValue({
      isStoreLayerEnabled: false,
      isPointsOfInterestLayerEnabled: false
    });
  }

  private subscribeToFormChanges(): void {
    this.addSubscription(
      this.form.valueChanges
        .pipe(startWith(this.form.getRawValue()), pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          const isFeatureTypeChanged = prev.isStoreLayerEnabled !== next.isStoreLayerEnabled;
          const featureType = next.isStoreLayerEnabled ? FeatureType.STORE : null;

          const newOptions: MtnMapOptions = {};
          if (isFeatureTypeChanged) {
            newOptions.featureType = featureType;
            newOptions.filterGroup = new FilterGroup();
          }
          if (prev.isPointsOfInterestLayerEnabled !== next.isPointsOfInterestLayerEnabled) {
            newOptions.isPointsOfInterestEnabled = next.isPointsOfInterestLayerEnabled;
          }

          this.map.setOptions(newOptions);
        })
    );
  }

  private updateForm(): void {
    this.form.get('isStoreLayerEnabled').setValue(this.toolbarState.isStoreLayerEnabled, {emitEvent: false});
    this.form.get('isPointsOfInterestLayerEnabled').setValue(this.toolbarState.isPointOfInterestLayerEnabled, {emitEvent: false});
  }

}
