<div class="store-detail-page-container container" fxFlexFill fxLayout="column" fxLayoutAlign="start center">
  <mtn-store-header-panel></mtn-store-header-panel>
  <div class="page-container container" *ngIf="store && (!hasLicense(LicenseType.STANDARD) || isMarketAreaLoaded)"
       fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="50px">
    <div fxLayout="row" fxLayoutGap="30px">
      <div fxFlex>
        <mtn-store-alerts-panel #alertsPanel [hidden]="!alertsPanel.alertsCount"></mtn-store-alerts-panel>
      </div>
      <div class="actions-toolbar">
        <mtn-store-actions-menu-button [storeUuids]="[store.uuid]" mtnAnalyticsEventTrigger
                                       [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                                       [analyticsEventParameters]="{'target': 'store-details-actions-menu-button'}"></mtn-store-actions-menu-button>
      </div>
    </div>
    <mtn-store-critical-insights-panel></mtn-store-critical-insights-panel>
    <mtn-store-sales-performance-panel></mtn-store-sales-performance-panel>
    <mtn-store-additional-insights-panel></mtn-store-additional-insights-panel>
    <mtn-store-demographic-profile-panel></mtn-store-demographic-profile-panel>
    <mtn-store-competitive-analysis-panel></mtn-store-competitive-analysis-panel>
    <mtn-store-banner-analysis-panel></mtn-store-banner-analysis-panel>
    <mtn-footer></mtn-footer>
  </div>
  <mtn-spinner [isPageSpinner]="true"
               [on]="!store || (hasLicense(LicenseType.STANDARD) && !isMarketAreaLoaded)"></mtn-spinner>
</div>
