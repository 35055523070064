import { Component } from '@angular/core';
import { Store } from '../../../core/models';
import { Store as NgrxStore } from '@ngrx/store';
import { AppState } from '../../../app-state';
import { AuthorizationAwareComponent } from '../../../core/authorization-aware-component';
import { DetailState, selectDetailState } from '../../detail-state';
import { filter, finalize, take, tap } from 'rxjs/operators';
import { BannerComparison } from '../../../core/banner/banner-comparison';

@Component({
  selector: 'mtn-store-banner-analysis-panel',
  templateUrl: './store-banner-analysis-panel.component.html',
  styleUrls: ['./store-banner-analysis-panel.component.scss']
})
export class StoreBannerAnalysisPanelComponent extends AuthorizationAwareComponent {

  public readonly PANEL_DESCRIPTION = 'Compare the entire banner\'s performance in the market. How does this store\'s banner compare to all other banners with a presence in the market?';

  comparisonSalesSqft: BannerComparison;
  comparisonSalesVolume: BannerComparison;
  comparisonStoreCount: BannerComparison;
  store: Store;
  marketName: any;
  isLoading = false;

  constructor(protected ngrxStore: NgrxStore<AppState>) {
    super(ngrxStore);
  }

  onAuthorizationChange(): void {
  }

  onAuthorizationInit(): void {
    this.subscribeToDetailState();
  }

  private subscribeToDetailState(): void {
    this.isLoading = true;
    this.addSubscription(
      selectDetailState(this.ngrxStore)
        .pipe(
          filter((state: DetailState) => !!state.store
            && !!state.bannerComparisonSalesSqft
            && !!state.bannerComparisonSalesVolume
            && !!state.bannerComparisonStoreCount),
          take(1),
          tap((state: DetailState) => {
            this.store = state.store;
            if (state.marketArea) {
              // @ts-ignore
              this.marketName = state.marketArea.getFeature().properties['NAME'];
            }
            this.comparisonSalesSqft = state.bannerComparisonSalesSqft;
            this.comparisonSalesVolume = state.bannerComparisonSalesVolume;
            this.comparisonStoreCount = state.bannerComparisonStoreCount;
          }),
          finalize(() => this.isLoading = false)
        )
        .subscribe()
    );
  }
}
