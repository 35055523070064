import { Component, Input } from '@angular/core';
import { RegionType } from '../../../location/region-type.enum';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'mtn-region-field',
  templateUrl: './region-field.component.html',
  styleUrls: ['./region-field.component.scss']
})
export class RegionFieldComponent {

  @Input()
  form: FormGroup;
  @Input()
  controlName: string = 'region';
  @Input()
  isMarketAreaAvailable = true;
  @Input()
  placeholder: string = 'Region';

  RegionType = RegionType;

  constructor() {
  }

}
