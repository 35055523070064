<div class="not-available-container container" fxFlexFill fxLayout="column" fxLayoutAlign="start center">
  <div class="text-container text-center" fxLayout="column" fxLayoutAlign="start center">
    <fa-icon class="error-icon" icon="exclamation-circle"></fa-icon>
    <h1 class="mat-h1 text-small-caps">Uh-oh!</h1>
    <h2 class="mat-h2">Something went wrong!</h2>
    <p>It appears that MTN Insights is not currently available. Contact support for more details.</p>
    <span><strong>Email:</strong>&nbsp;<a
      href="mailto:support@mtnra.com?subject=Insights%20Not%20Available">support@mtnra.com</a></span>
  </div>
</div>
