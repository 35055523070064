import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthorizationAwareComponent } from '../../core/authorization-aware-component';
import { AppState } from '../../app-state';
import { Store as NgrxStore } from '@ngrx/store';
import { MtnMap } from '../mtn-map';
import { FilterToolbarState } from './filter-toolbar-state';
import { MapEvent, MapEventType } from '../map-events';
import { filter, finalize, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';
import { UserProfileService } from '../../core/user-profile/user-profile.service';
import { Pageable } from '../../core/service/pageable';
import { SavedSearch } from '../../core/federation/filter/saved-search';
import { AddOrEditSavedSearchWizard } from './add-or-edit-saved-search-wizard/add-or-edit-saved-search-wizard';
import { WizardRunnerService } from '../../core/wizard/wizard-runner.service';
import { MatOptionSelectionChange } from '@angular/material/core';
import { FilterGroup } from '../../core/federation/filter/filter-group';
import { FilterGroupService } from '../../core/federation/filter/filter-group.service';
import { SavedSearchService } from '../../core/federation/filter/saved-search.service';
import { forkJoin, Observable } from 'rxjs';
import { Filter } from '../../core/federation/filter/filter';
import { FilterService } from '../../core/federation/filter/filter.service';
import { ToastService } from '../../core/toast/toast.service';
import { FilterType } from '../../core/federation/filter/filter-type.enum';
import { ConfirmActionWizard } from '../../core/confirm-action-wizard/confirm-action-wizard';

@Component({
  selector: 'mtn-filter-toolbar',
  templateUrl: './filter-toolbar.component.html',
  styleUrls: ['./filter-toolbar.component.scss']
})
export class FilterToolbarComponent extends AuthorizationAwareComponent implements OnInit {

  @Input()
  map: MtnMap;

  form = new FormGroup({
    savedSearch: new FormControl()
  });
  isSavedSearchesLoaded = false;
  isSaving = false;
  isUnsavedChangesPresent = false;
  originalFilterGroup: FilterGroup;
  savedSearches: SavedSearch[] = [];
  toolbarState: FilterToolbarState;

  constructor(private filterService: FilterService,
              private filterGroupService: FilterGroupService,
              protected ngrxStore: NgrxStore<AppState>,
              private savedSearchService: SavedSearchService,
              private toaster: ToastService,
              private userProfileService: UserProfileService,
              private wizardRunner: WizardRunnerService) {
    super(ngrxStore);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  onAuthorizationChange(): void {
  }

  onAuthorizationInit(): void {
    this.updateState();
    this.loadMapFilterGroupFromServer();
    this.loadSavedSearchOptions();
    this.subscribeToFilterChanges();
  }

  clearAllFilters(): void {
    const filterGroup = _.cloneDeep(this.map.options.filterGroup);
    filterGroup.filters = [];
    this.map.setOptions({
      filterGroup: filterGroup
    });
  }

  handleSavedSearchSelected(event: MatOptionSelectionChange): void {
    if (event.isUserInput) {
      if (event.source.value) {
        //If the selected SavedSearch doesn't have its filterGroup loaded, we need to go get it
        if (!event.source.value.filterGroup?.filters?.length) {
          this.savedSearchService.findOne(event.source.value.uuid)
            .subscribe((result: SavedSearch) => {
              //Update our local cache of saved searches
              const existing = _.find(this.savedSearches, (candidate: SavedSearch) => candidate.uuid === result.uuid);
              if (existing) {
                existing.filterGroup = _.cloneDeep(result.filterGroup);
              }

              this.storeOriginalFilterGroupForChangeDetection(result.filterGroup);

              //Update the map
              this.map.setOptions({
                filterGroup: _.cloneDeep(result.filterGroup)
              });
            });
        }
        //Else, just apply the selected SavedSearch's filterGroup to the map
        else {
          this.storeOriginalFilterGroupForChangeDetection(event.source.value.filterGroup);

          this.map.setOptions({
            filterGroup: _.cloneDeep(event.source.value.filterGroup)
          });
        }
      }
    } else {
      this.makeMapFilterGroupAnonymous();
      this.originalFilterGroup = null;
      this.isUnsavedChangesPresent = false;
    }
  }

  openConfirmDeleteWizard(request: SavedSearch): void {
    const wizard = new ConfirmActionWizard();
    wizard.model = {
      title: 'Delete Saved Search',
      text: `Are you sure you want to delete the "${request.name}" Saved Search? This cannot be undone.`,
      onConfirm: this.savedSearchService.deleteOne(request.uuid)
    };

    this.wizardRunner.run(wizard)
      .afterClosed()
      .subscribe((result: ConfirmActionWizard) => {
        if (result.model.result) {
          this.toaster.info("Successfully deleted Saved Search");

          this.savedSearches = _.reject(this.savedSearches, (candidate: SavedSearch) => candidate.uuid === request.uuid);

          /*
          If the selected SavedSearch happens to be the parent of the current map FilterGroup, strip the map's
          FilterGroup and its filters of all ids and uuids to prevent problems later on.
           */
          if (request.filterGroup.uuid === this.originalFilterGroup?.uuid) {
            this.originalFilterGroup = null;
            this.isUnsavedChangesPresent = false;

            this.makeMapFilterGroupAnonymous();
          }
        }
      });
  }

  openNewSavedSearchWizard(): void {
    const wizard = new AddOrEditSavedSearchWizard();
    wizard.model = {
      map: this.map
    }

    this.wizardRunner.run(wizard)
      .afterClosed()
      .subscribe((result: AddOrEditSavedSearchWizard) => {
        if (result.model.result) {
          this.savedSearches.push(result.model.result);
          this.savedSearches = _.orderBy(this.savedSearches, 'name', 'desc');
          this.map.setOptions({
            filterGroup: result.model.result.filterGroup
          })
        }
      });
  }

  saveFilterGroupChanges(): void {
    this.isSaving = true;

    const tasks: Observable<any>[] = this.buildSaveTasksForFilterGroup(this.map.options.filterGroup);

    forkJoin(tasks)
      .pipe(
        switchMap(() => this.filterGroupService.findOne(this.originalFilterGroup.uuid)),
        finalize(() => this.isSaving = false)
      )
      .subscribe((filterGroup: FilterGroup) => {
        this.toaster.info('Successfully updated Saved Search');
        this.originalFilterGroup = _.cloneDeep(filterGroup);
        this.map.setOptions({
          filterGroup: _.cloneDeep(filterGroup)
        });
      });
  }

  private buildSaveTasksForFilterGroup(filterGroup: FilterGroup): Observable<any>[] {
    const tasks: Observable<any>[] = [];

    //Prepare lists of filters to compare, excluding GEOJSON filters
    const originalFilters = _.reject(this.originalFilterGroup.filters, (candidate: Filter<any>) => candidate.type === FilterType.GEOJSON);
    const requestFilters = _.reject(filterGroup.filters, (candidate: Filter<any>) => candidate.type === FilterType.GEOJSON);

    //First go through the original to find removals and edits
    originalFilters.forEach((originalFilter: Filter<any>) => {
      //Find the matching request filter
      const requestFilter = filterGroup.getFilterByUuid(originalFilter.uuid);

      //If we found a match, check for changes in the value and create a task if necessary
      if (requestFilter && !_.isEqual(requestFilter.value, originalFilter.value)) {
        tasks.push(
          this.filterService.updateOne(requestFilter)
        );
      }
      //If we didn't find a match, it's been deleted, so create a delete task
      else if (!requestFilter) {
        tasks.push(
          this.filterService.deleteOne(originalFilter.uuid)
        );
      }
    });

    //Then go through the new to find additions
    const newFilters = _.filter(requestFilters, (candidate: Filter<any>) => !candidate.uuid);
    newFilters.forEach((newFilter: Filter<any>) => {
      tasks.push(
        this.filterGroupService.addOneFilter(filterGroup.uuid, newFilter)
      );
    });

    return tasks;
  }

  private loadMapFilterGroupFromServer(): void {
    if (this.map.options.filterGroup?.uuid) {
      this.filterGroupService.findOne(this.map.options.filterGroup.uuid)
        .subscribe((filterGroup: FilterGroup) => {
          this.originalFilterGroup = filterGroup;

          this.runSavedSearchChangeDetection();
        });
    }
  }

  private loadSavedSearchOptions(): void {
    this.isSavedSearchesLoaded = false;
    this.userProfileService.findOnesSavedSearches(this.currentUser.uuid)
      .pipe(finalize(() => this.isSavedSearchesLoaded = true))
      .subscribe((results: Pageable<SavedSearch>) => {
        this.savedSearches = _.orderBy(results.content, 'name');
        this.updateSelectedSavedSearch();
      });
  }

  private makeMapFilterGroupAnonymous(): void {
    if (this.map.options.filterGroup?.uuid) {
      //TODO replace this with filterGroup.anonymize() and test
      const copy = _.cloneDeep(this.map.options.filterGroup);
      copy.id = null;
      copy.uuid = null;

      copy.filters.forEach((filter: Filter<any>) => {
        filter.id = null;
        filter.uuid = null;
      });

      this.map.setOptions({
        filterGroup: copy
      });
    }
  }

  private runSavedSearchChangeDetection(): void {
    if (this.originalFilterGroup && this.map.options.filterGroup?.uuid === this.originalFilterGroup.uuid) {
      //We take a copy of the map's FilterGroup, and see if we have any pending save tasks
      const mapCopy = _.cloneDeep(this.map.options.filterGroup);
      this.isUnsavedChangesPresent = !!this.buildSaveTasksForFilterGroup(mapCopy).length;
    }
  }

  /**
   * We store a copy of the selected FilterGroup, minus any GeoJson filter, so we can watch for changes and update
   * filters appropriately.
   */
  private storeOriginalFilterGroupForChangeDetection(filterGroup: FilterGroup): void {
    const copy = _.cloneDeep(filterGroup);
    copy.filters = _.reject(copy.filters, (candidate: Filter<any>) => candidate.type === FilterType.GEOJSON);
    this.originalFilterGroup = copy;
    this.isUnsavedChangesPresent = false;
  }

  private subscribeToFilterChanges(): void {
    this.addSubscription(
      this.map.events
        .pipe(filter((event: MapEvent<any>) => event.type === MapEventType.FILTER_CHANGE))
        .subscribe(() => {
          this.updateState();
          this.updateSelectedSavedSearch();
          this.runSavedSearchChangeDetection();
        })
    );
  }

  private updateSelectedSavedSearch(): void {
    if (this.map.options.filterGroup?.uuid) {
      const selectedSavedSearch = _.find(this.savedSearches, (savedSearch: SavedSearch) => {
        return savedSearch.filterGroup.uuid === this.map.options.filterGroup.uuid;
      });
      this.form.get('savedSearch').setValue(selectedSavedSearch, {emitEvent: false});
    }
  }

  private updateState(): void {
    this.toolbarState = new FilterToolbarState(this.map);
  }

}
