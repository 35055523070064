import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UserProfile } from '../../../user-profile/user-profile';
import { BaseComponent } from '../../../base-component';
import { FormService } from '../form.service';

@Component({
  selector: 'mtn-user-profile-form',
  templateUrl: './user-profile-form.component.html',
  styleUrls: ['./user-profile-form.component.scss']
})
export class UserProfileFormComponent extends BaseComponent implements OnInit {

  @Input()
  controlName = 'userProfile';
  @Input()
  parentForm: FormGroup;
  @Input()
  userProfile: UserProfile;

  form = new FormGroup({});

  constructor(private formService: FormService) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.form = this.formService.buildUserProfileForm(this.userProfile);
    this.parentForm.addControl(this.controlName, this.form);
  }

}
