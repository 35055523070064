<div class="spinner {{color}}" [ngClass]="{rounded: rounded}" fxLayout="row" fxLayoutAlign="center center" *ngIf="on"
     mtnClickTrap>
  <div class="loading-indicator-container" fxLayoutAlign="center center" *ngIf="isPageSpinner; else defaultSpinner">
    <img src="../../../../assets/images/cube-preloader.svg" height="180px" width="180px;" alt="MTN Loading Indicator"/>
  </div>
  <ng-template #defaultSpinner>
    <div>
      <mat-spinner mode="indeterminate" [diameter]="size"></mat-spinner>
    </div>
  </ng-template>
</div>
