import { Component } from '@angular/core';
import { AuthorizationAwareComponent } from '../../../core/authorization-aware-component';
import { FormControl } from '@angular/forms';
import { Store as NgrxStore } from '@ngrx/store';
import { AppState } from '../../../app-state';
import { UserPreferencesService } from '../../../core/user-preferences/user-preferences.service';
import * as _ from 'lodash';
import { UserPreferences } from '../../../core/user-preferences/user-preferences';
import { UserSetUserPreferences } from '../../../auth/user-actions';
import Timeout = NodeJS.Timeout;

@Component({
  selector: 'mtn-map-controls-legend-assembly',
  templateUrl: './map-controls-legend-assembly.component.html',
  styleUrls: ['./map-controls-legend-assembly.component.scss']
})
export class MapControlsLegendAssemblyComponent extends AuthorizationAwareComponent {

  isHidden = false;
  isHiding = false;
  isShowing = false;
  isShown = false;
  preferenceControl: FormControl;

  private _activeTimeout: Timeout;
  private _peekTimeout: Timeout;

  constructor(protected ngrxStore: NgrxStore<AppState>,
              private preferencesService: UserPreferencesService) {
    super(ngrxStore);
  }

  onAuthorizationChange(): void {
  }

  onAuthorizationInit(): void {
    this.initForm();
    if (this.userPreferences.showMapLegendOnMapLoad) {
      this.peek();
    } else {
      this.isHidden = true;
    }
  }

  closeSlide(): void {
    this.stopActiveTransition();

    this.isShown = false;
    this.isHiding = true;

    this._activeTimeout = setTimeout(() => {
      this.isHiding = false;
      this.isHidden = true;
    }, 600);
  }

  openSlide(): void {
    this.stopActiveTransition();

    this.isHidden = false;
    this.isShowing = true;

    this._activeTimeout = setTimeout(() => {
      this.isShowing = false;
      this.isShown = true;
    }, 100);
  }

  toggleSlide(): void {
    if (this.isShowing || this.isShown) {
      this.closeSlide();
    } else {
      this.openSlide();
    }
  }

  private initForm(): void {
    this.preferenceControl = new FormControl(this.userPreferences.showMapLegendOnMapLoad);
    this.addSubscription(
      this.preferenceControl.valueChanges
        .subscribe((value: boolean) => {
          const request = _.cloneDeep(this.userPreferences);
          request.showMapLegendOnMapLoad = value;

          this.preferencesService.updateOne(request)
            .subscribe((result: UserPreferences) => {
              this.ngrxStore.dispatch(UserSetUserPreferences({preferences: result}));
            });
        })
    );
  }

  private peek(): void {
    setTimeout(() => this.closeSlide(), 5000);
  }

  private stopActiveTransition(): void {
    if (this._peekTimeout) {
      clearTimeout(this._peekTimeout);
      this._peekTimeout = null;
    }
    if (this._activeTimeout) {
      clearTimeout(this._activeTimeout);
      this._activeTimeout = null;
    }
  }

}
