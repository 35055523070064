<div class="store-competition-analysis-panel-container container" [ngClass]="{'default-padding': !hasStandardLicense}">
  <mtn-restricted-license-overlay *ngIf="!hasStandardLicense"></mtn-restricted-license-overlay>
  <div class="header-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
    <h1 class="mat-h1 color-primary text-small-caps no-bottom-margin">Store Competitive Analysis</h1>
    <mtn-what-is-this [text]="PANEL_DESCRIPTION"></mtn-what-is-this>
    <div fxFlex></div>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
      <div class="key-indicator-container" fxLayout="row" fxLayoutGap="5px" [ngSwitch]="form.get('keyIndicator').value">
        <span class="color-muted">Current Indicator:</span>
        <span *ngSwitchCase="KeyIndicatorType.SALES_SQFT">{{Dictionary.labelSalesSqft}}</span>
        <span *ngSwitchCase="KeyIndicatorType.SALES_VOLUME">{{Dictionary.labelSalesVolume}}</span>
      </div>
      <mtn-icon-button matTooltip="Options" [matMenuTriggerFor]="optionsMenu"
                       mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.TOGGLE_MENU"
                       [analyticsEventParameters]="{'target': 'competitive-analysis-options-menu'}"
                       [disabled]="!volume || !volume?.salesArea"
                       [ngClass]="{disabled: !volume || !volume?.salesArea}">
        <fa-icon icon="cog"></fa-icon>
      </mtn-icon-button>
      <mtn-icon-button color="accent" (onClick)="openAddComparisonWizard()"
                       [matTooltip]="comparisons?.length >= 5 ? 'Comparison Limit Reached' : 'Add Comparison'"
                       mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                       [analyticsEventParameters]="{'target': 'competitive-analysis-add-comparison-button'}"
                       [disabled]="comparisons?.length >= 5 || !volume || !volume?.salesArea">
        <fa-icon icon="plus"></fa-icon>
      </mtn-icon-button>
    </div>
  </div>
  <hr style="margin-bottom: 30px;"/>
  <div class="content-container" fxLayout="column" fxLayoutGap="15px" [formGroup]="form"
       *ngIf="store.isActive() && volume && volume.salesArea; else missingData">
    <div class="comparisons-container container" fxLayout="column" fxLayoutGap="15px">
      <div class="comparison-container container" fxLayout="row" fxLayoutGap="15px"
           *ngFor="let comparison of comparisons; let i = index; let last = last;">
        <mtn-competitive-analysis-card [comparisonRequest]="comparison"
                                       [isDummyComparison]="!hasStandardLicense"></mtn-competitive-analysis-card>
        <div class="number-line-chart-container container" fxFlex>
          <mtn-number-line-chart [dataset]="numberLineDatasets[i]" [axisBuilder]="axisBuilder"
                                 [showAxis]="last"
                                 *ngIf="comparison.isChartable(); else unchartable"></mtn-number-line-chart>
          <ng-template #unchartable>
            <div fxFlexFill>
              <div class="color-muted" style="width:100%; text-align:center; padding-top: 45px;">
                <div>{{comparison.unchartableReason}}</div>
              </div>
            </div>
          </ng-template>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <mtn-icon-button color="warn" matTooltip="Remove Comparison" (onClick)="removeComparison(comparison)"
                           mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                           [analyticsEventParameters]="{'target': 'competitive-analysis-remove-comparison-button'}">
            <fa-icon icon="ban"></fa-icon>
          </mtn-icon-button>
        </div>
      </div>
    </div>
    <div class="help-link-container" fxLayout="row" fxLayoutAlign="start center">
      <span *ngIf="comparisons?.length < 5; else noMoreComparisons"><a class="color-help" href="javascript:void(0)"
                                                                       mtnAnalyticsEventTrigger
                                                                       [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                                                                       [analyticsEventParameters]="{'target': 'competitive-analysis-add-comparison-link'}"
                                                                       (click)="openAddComparisonWizard()">Add a Comparison</a> to compare other metrics that matter to you</span>
      <ng-template #noMoreComparisons>
        <span class="color-muted">You've reached the limit for comparisons. Remove one to make room for another.</span>
      </ng-template>
      <div fxFlex></div>
    </div>
  </div>
  <div style="position:relative;">
    <mtn-spinner [on]="isLoading" [size]="SpinnerSize.LARGE"></mtn-spinner>
  </div>
</div>

<mat-menu class="right-aligned-menu" #optionsMenu="matMenu">
  <form class="default-padding" [formGroup]="form" fxLayout="column" (click)="stopPropagation($event)">
    <mtn-key-indicator-field [form]="form" controlName="keyIndicator" appearance="outline"></mtn-key-indicator-field>
  </form>
</mat-menu>

<ng-template #missingData>
  <div class="missing-data-row" fxLayoutAlign="center center">
    <span class="color-muted">{{Dictionary.labelStoreMissingRequiredData}}</span>
  </div>
</ng-template>
