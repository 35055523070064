import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MtnValidators } from '../../core/form-controls/mtn-validators';
import { BaseComponent } from '../../core/base-component';
import { RegistrationRequest } from './registration-request';
import { Company } from '../../core/company/company';
import { UserProfile } from '../../core/user-profile/user-profile';
import { RegistrationService } from './registration.service';
import { ToastService } from '../../core/toast/toast.service';
import { AuthenticationService } from '../../auth/authentication/authentication.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'mtn-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent extends BaseComponent implements OnInit {

  accountForm = new FormGroup({
    authorization: new FormControl(false, [Validators.requiredTrue]),
    email: new FormControl(null, [Validators.required, Validators.email, Validators.maxLength(255)]),
    password: new FormControl(null, [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(64),
      MtnValidators.matchesPattern(/\d/, {missingNumber: true}),
      MtnValidators.matchesPattern(/[A-Z]/, {missingUpperCase: true}),
      MtnValidators.matchesPattern(/[a-z]/, {missingLowerCase: true}),
      MtnValidators.matchesPattern(/[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/, {missingSpecialCharacter: true})
    ]),
    confirmPassword: new FormControl(null, [Validators.required])
  });
  currentForm: FormGroup;
  currentStep = 0;
  isPasswordShown = false;
  isSignedUp = false;
  isSubmitting = false;
  organizationForm = new FormGroup({
    name: new FormControl(null, [Validators.required, Validators.maxLength(64)]),
    type: new FormControl(),
    description: new FormControl(null, [Validators.maxLength(64)]),
    hasRegistered: new FormControl(false),
    userCount: new FormControl()
  });
  organizationTypes = [
    'Developer',
    'Financial Institution',
    'Grocer',
    'Real Estate Investor',
    'Other'
  ];
  steps = [
    'Tell us about yourself',
    'And your organization',
    'Create your account'
  ];
  userCountOptions = [
    '1-10',
    '11-25',
    '26-50',
    '50+'
  ];
  userForm = new FormGroup({
    firstName: new FormControl(null, [Validators.required, Validators.maxLength(64)]),
    lastName: new FormControl(null, [Validators.required, Validators.maxLength(64)]),
    title: new FormControl(null, [Validators.maxLength(64)]),
    phoneNumber: new FormControl(null, [Validators.minLength(10), Validators.maxLength(10)]),
    hope: new FormControl(null, [Validators.maxLength(500)])
  });

  constructor(private authenticationService: AuthenticationService,
              private recaptchaService: ReCaptchaV3Service,
              private registrationService: RegistrationService,
              private toaster: ToastService) {
    super();
  }

  ngOnInit(): void {
    this.setFormForCurrentStep();
    this.watchPasswordFields();
  }

  back(): void {
    if (this.currentStep > 0) {
      this.currentStep--;
    }
    this.setFormForCurrentStep();
  }

  next(): void {
    if (this.currentStep < 2) {
      this.currentStep++;
    } else {
      this.submitRequest();
    }
    this.setFormForCurrentStep();
  }

  signIn(): void {
    this.authenticationService.openAuth0AuthenticationDialog();
  }

  private buildRequest(token: string): RegistrationRequest {
    const accountFormValue = this.accountForm.getRawValue();
    const organizationFormValue = this.organizationForm.getRawValue();
    const userFormValue = this.userForm.getRawValue();

    const request = new RegistrationRequest();
    request.recaptchaToken = token;

    const company = new Company();
    company.name = organizationFormValue.name;
    request.company = company;

    request.companyType = organizationFormValue.type;
    request.companyTypeDescription = organizationFormValue.description;
    request.hasRegistered = organizationFormValue.hasRegistered;
    request.userCount = organizationFormValue.userCount;

    const userProfile = new UserProfile();
    userProfile.email = accountFormValue.email;
    userProfile.password = accountFormValue.password;
    userProfile.firstName = userFormValue.firstName;
    userProfile.lastName = userFormValue.lastName;
    userProfile.title = userFormValue.title;
    userProfile.phoneNumber = userFormValue.phoneNumber;
    request.userProfile = userProfile;

    request.hope = userFormValue.hope;

    return request;
  }

  private checkPasswordsMatch(): void {
    const password = this.accountForm.get('password').value;
    const confirmPassword = this.accountForm.get('confirmPassword').value;

    if (password && confirmPassword && password !== confirmPassword) {
      this.accountForm.get('confirmPassword').setErrors({noPasswordMatch: true});
    }
  }

  private setFormForCurrentStep(): void {
    if (this.currentStep === 0) {
      this.currentForm = this.userForm;
    } else if (this.currentStep === 1) {
      this.currentForm = this.organizationForm;
    } else if (this.currentStep === 2) {
      this.currentForm = this.accountForm;
    }
  }

  private submitRequest(): void {
    if (!this.isSubmitting) {
      this.isSubmitting = true;

      this.addSubscription(
        this.recaptchaService.execute('register')
          .pipe(
            catchError(((err) => {
              this.toaster.error('Uh-oh! Something went wrong!');
              throw err;
            })),
            switchMap((token: string) => {
              const request = this.buildRequest(token);

              return this.registrationService.registerOne(request)
                .pipe(
                  finalize(() => this.isSubmitting = false),
                  map(() => this.isSignedUp = true)
                )
            }))
          .subscribe()
      );
    }
  }

  private watchPasswordFields(): void {
    //Check for password match when confirmPassword changed
    this.addSubscription(
      this.accountForm.get('confirmPassword').valueChanges
        .subscribe(() => this.checkPasswordsMatch())
    );
    //Reset confirmPassword field if password changed
    this.addSubscription(
      this.accountForm.get('password').valueChanges
        .subscribe(() => {
          this.accountForm.get('confirmPassword').reset();
        })
    );
  }

}
