import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WizardPageComponent } from '../../../../../../core/wizard/wizard-page-component';
import { InviteUserToCompanyWizard } from '../invite-user-to-company-wizard';
import { Observable } from 'rxjs';
import { CompanyService } from '../../../../../../core/company/company.service';
import { ToastService } from '../../../../../../core/toast/toast.service';
import { map } from 'rxjs/operators';
import { CompanyInvitation, CompanyInvitationType } from '../../../../../../core/company/company';

@Component({
  selector: 'mtn-invite-user-to-company-page',
  templateUrl: './invite-user-to-company-page.component.html',
  styleUrls: ['./invite-user-to-company-page.component.scss']
})
export class InviteUserToCompanyPageComponent extends WizardPageComponent<InviteUserToCompanyWizard> {

  key = 'invite-user-to-company';

  form = new FormGroup({
    firstName: new FormControl(null, [Validators.required, Validators.maxLength(64)]),
    lastName: new FormControl(null, [Validators.required, Validators.maxLength(64)]),
    email: new FormControl(null, [Validators.required, Validators.maxLength(255), Validators.email]),
    type: new FormControl(CompanyInvitationType.MEMBER, [Validators.required])
  });

  CompanyInvitationType = CompanyInvitationType;

  constructor(private companyService: CompanyService,
              private toaster: ToastService) {
    super();
    this.title = 'Invite User';
    this.nextButtonText = 'Submit';
    this.closeButtonText = 'Cancel';
  }

  onLoad(): Observable<any> {
    return super.onLoad();
  }

  onNext(): Observable<string> {
    const request = this.buildRequest();
    return this.companyService.addOneInvitation(request.company.uuid, request)
      .pipe(map((result: CompanyInvitation) => {
        this.wizard.model.invitation = result;
        this.toaster.info('Invitation successfully sent');
        return null;
      }));
  }

  private buildRequest(): CompanyInvitation {
    const request = new CompanyInvitation();
    const formValue = this.form.getRawValue();

    request.company = this.wizard.model.company;
    request.type = formValue.type;
    request.emailAddress = formValue.email;
    request.firstName = formValue.firstName;
    request.lastName = formValue.lastName;

    return request;
  }

}
