import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MtnMap } from '../../mtn-map';
import { FilterToolbarState } from '../filter-toolbar-state';
import { AuthorizationAwareComponent } from '../../../core/authorization-aware-component';
import { AppState } from '../../../app-state';
import { Store as NgrxStore } from '@ngrx/store';
import { FormControl, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { Banner } from '../../../core/models';
import { Subject } from 'rxjs';
import { FilterType } from '../../../core/federation/filter/filter-type.enum';
import { BannerFilter } from '../../../core/federation/filter/filter';
import { EXCLUSIVE_FILTER_DEFINITION } from '../../../core/util/string-utils';

@Component({
  selector: 'mtn-banner-filter-assembly',
  templateUrl: './banner-filter-assembly.component.html',
  styleUrls: ['./banner-filter-assembly.component.scss']
})
export class BannerFilterAssemblyComponent extends AuthorizationAwareComponent implements OnChanges, OnInit {

  @Input()
  map: MtnMap;
  @Input()
  toolbarState: FilterToolbarState;

  form = new FormGroup({
    bannerSearch: new FormControl(),
    isExclusive: new FormControl()
  });
  EXCLUSIVE_FILTER_DEFINITION = EXCLUSIVE_FILTER_DEFINITION;
  uuids: string[] = [];

  private _bannerChanges$ = new Subject();

  constructor(protected ngrxStore: NgrxStore<AppState>) {
    super(ngrxStore);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.toolbarState) {
      this.updateForm();
    }
  }

  onAuthorizationChange(): void {
  }

  onAuthorizationInit(): void {
    this.updateForm();
    this.subscribeToBannerChanges();
  }

  addBanner(banner: Banner): void {
    if (!_.includes(this.uuids, banner.uuid)) {
      this.uuids.push(banner.uuid);
    }
    this.form.get('bannerSearch').setValue(null);
    this._bannerChanges$.next();
  }

  clear(): void {
    this.uuids = [];
    this.form.get('bannerSearch').setValue(null);
    this._bannerChanges$.next();
  }

  removeBanner(uuid: string): void {
    let index = _.findIndex(this.uuids, (candidate: string) => candidate === uuid);
    if (index !== -1) {
      this.uuids.splice(index, 1);
    }
    this.form.get('bannerSearch').setValue(null);
    this._bannerChanges$.next();
  }

  private subscribeToBannerChanges(): void {
    this.addSubscription(
      this.form.get('isExclusive').valueChanges
        .subscribe(() => this._bannerChanges$.next())
    );

    this.addSubscription(
      this._bannerChanges$.subscribe(() => {
        const filterGroup = _.cloneDeep(this.map.options.filterGroup);

        if (this.uuids.length) {
          const isExclusive = this.form.getRawValue().isExclusive;

          //If the map has a BANNER filter, we need to update it
          let filter = filterGroup.getOwnFilter(FilterType.BANNER);
          //Else, we need to add one
          if (!filter) {
            filter = new BannerFilter();
          }

          filter.value = [...this.uuids];
          filter.isExclusive = isExclusive;

          filterGroup.clearFilter(FilterType.BANNER);
          filterGroup.filters.push(filter);
          this.map.setOptions({
            filterGroup: filterGroup
          });
        } else {
          //If the map has a BANNER filter, we need to remove it
          if (filterGroup.hasOwnFilter(FilterType.BANNER)) {
            filterGroup.clearFilter(FilterType.BANNER);
            this.map.setOptions({
              filterGroup: filterGroup
            });
          }
          //Else, we don't need to do anything
        }
      })
    );
  }

  private updateForm(): void {
    let uuids: string[] = [];

    if (this.toolbarState.filterBanners?.value?.length) {
      uuids.push.apply(uuids, this.toolbarState.filterBanners.value);
    }

    this.uuids = uuids;
    this.form.get('bannerSearch').setValue(null, {emitEvent: false});
    this.form.get('isExclusive').setValue(this.toolbarState.filterBanners?.isExclusive, {emitEvent: false});
  }

}
