import { Component } from '@angular/core';
import { WizardPageComponent } from '../../../../core/wizard/wizard-page-component';
import { WelcomeWizard } from '../welcome-wizard';
import { Observable } from 'rxjs';
import { MapMode } from '../../../../map/map-mode.enum';
import { MtnMapOptions } from '../../../../map/mtn-map-options';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { buildDefaultMapSnapshot } from '../../../../map/map-snapshot';
import { UserPreferences } from '../../../../core/user-preferences/user-preferences';
import { UserSetUserPreferences } from '../../../user-actions';
import { ToastService } from '../../../../core/toast/toast.service';
import { UserPreferencesService } from '../../../../core/user-preferences/user-preferences.service';
import { AppState } from '../../../../app-state';
import { Store as NgrxStore } from '@ngrx/store';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { MtnMap } from '../../../../map/mtn-map';
import { FilterGroup } from '../../../../core/federation/filter/filter-group';
import MapTypeId = google.maps.MapTypeId;

@Component({
  selector: 'mtn-welcome-wizard-map-page',
  templateUrl: './welcome-wizard-map-page.component.html',
  styleUrls: ['./welcome-wizard-map-page.component.scss']
})
export class WelcomeWizardMapPageComponent extends WizardPageComponent<WelcomeWizard> {

  key = 'map';

  MapMode = MapMode;
  MapTypeId = MapTypeId;
  mapOptions: MtnMapOptions;

  private map: MtnMap;

  onMapReady(map: MtnMap): void {
    this.map = map;
  }

  constructor(private ngrxStore: NgrxStore<AppState>,
              private toaster: ToastService,
              private userPreferencesService: UserPreferencesService) {
    super();
    this.title = 'Welcome! Let\'s get started.';
    this.stepperText = 'Maps';
    this.backButtonText = 'Back';
    this.closeButtonText = 'Close';
    this.footerCheckboxText = 'Don\'t show again';
    this.headerText = 'Map Preferences';
    this.nextButtonText = 'Next';
  }

  onClose(): Observable<null> {
    if (this.wizard.isFooterCheckboxChecked) {
      const request = _.cloneDeep(this.wizard.model.userPreferences);
      request.isWelcomeWizardComplete = true;

      return this.userPreferencesService.updateOne(request)
        .pipe(map(() => null));
    } else {
      return super.onClose();
    }
  }

  onLoad(): Observable<any> {
    this.initForm();
    this.initMap();
    return super.onLoad();
  }

  onNext(): Observable<string> {
    const formValue = this.form.getRawValue();

    const request = this.wizard.model.request;
    request.mapDefaultMode = formValue.mapDefaultMode;
    request.mapDefaultType = formValue.mapDefaultType;
    request.isWelcomeWizardComplete = true;

    return this.userPreferencesService.updateOne(request)
      .pipe(map((result: UserPreferences) => {
        this.ngrxStore.dispatch(UserSetUserPreferences({preferences: result}));
        this.toaster.info('Successfully updated preferences');
        return 'end';
      }));
  }

  private initForm(): void {
    this.form = new FormGroup({
      mapDefaultMode: new FormControl(this.wizard.model.userPreferences.mapDefaultMode, [Validators.required]),
      mapDefaultType: new FormControl(this.wizard.model.userPreferences.mapDefaultType, [Validators.required])
    });

    this.subscribeToFormChanges();
  }

  private initMap(): void {
    const snapshot = buildDefaultMapSnapshot(this.wizard.model.userPreferences.mapDefaultMode, this.wizard.model.userPreferences.mapDefaultType);
    const options = snapshot.options;
    options.key = 'welcome-wizard-preferences-map-2';
    options.filterGroup = new FilterGroup();
    options.isAutoSaveEnabled = false;
    options.isAutoUpdateEnabled = false;
    options.isFeatureMap = false;
    options.controlConfiguration.isGoogleSearchEnabled = false;
    options.controlConfiguration.isFiltersEnabled = false;
    options.controlConfiguration.isMapModeEnabled = false;
    options.controlConfiguration.isMapTypeEnabled = false;
    options.controlConfiguration.isRecenterEnabled = false;
    options.controlConfiguration.isStreetViewEnabled = false;
    options.controlConfiguration.isTiltAndRotateEnabled = false;
    options.controlConfiguration.isZoomEnabled = false;

    this.mapOptions = options;
  }

  private subscribeToFormChanges(): void {
    this.addSubscription(
      this.form.valueChanges
        .subscribe((value: any) => {
          if (this.map) {
            this.map.setOptions({
              mode: value.mapDefaultMode,
              mapTypeId: value.mapDefaultType
            });
          }
        })
    );
  }

}
