<div class="add-comparison-page-container container">
  <div fxLayout="row" fxLayoutGap="15px">
    <mtn-comparison-type-field [form]="form"></mtn-comparison-type-field>
    <div fxLayout="column">
      <mtn-region-field [form]="form"
                        [isMarketAreaAvailable]="!!wizard.model.marketArea?.getFeature()"></mtn-region-field>
      <mtn-drive-time-minutes-field [form]="form"
                                    *ngIf="form.get('region').value === RegionType.DRIVE_TIME"></mtn-drive-time-minutes-field>
      <mtn-ring-miles-field [form]="form"
                            *ngIf="form.get('region').value === RegionType.RING"></mtn-ring-miles-field>
    </div>
  </div>
</div>
