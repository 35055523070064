<div class="navigation-sidebar container" fxLayout="column" [ngClass]="{expanded: !isCollapsed}"
     [ngClass.lt-md]="{expanded: false}">
  <mtn-my-company-widget [condensedMode]="isCollapsed"></mtn-my-company-widget>
  <div class="interactive-button-list" fxLayout="column" fxLayoutGap="5px">
    <div class="divider"></div>
    <!--    <mtn-navigation-button icon="chart-bar" label="Dashboard" path="dashboard"-->
    <!--                           [condensedMode]="isCollapsed"></mtn-navigation-button>-->
    <mtn-navigation-button icon="cogs" label="Administration" path="administration"
                           *ngIf="isAdministrator" [condensedMode]="isCollapsed"></mtn-navigation-button>
    <mtn-navigation-button icon="folders" label="Collections" path="collection"
                           [condensedMode]="isCollapsed"></mtn-navigation-button>
    <mtn-navigation-button icon="search-location" label="Map Search" path="map-search"
                           [condensedMode]="isCollapsed"></mtn-navigation-button>
    <mtn-navigation-button icon="list-ul" label="Details" [path]="detailsPath"
                           [isDisabled]="!detailsPath" [condensedMode]="isCollapsed"></mtn-navigation-button>
    <button class="collapse-toggle" mat-mini-fab color="accent" [fxHide.lt-md]="true" fxLayoutAlign="center center"
            (click)="toggleIsCollapsed()" [matTooltip]="isCollapsed ? 'Expand Navigation' : 'Collapse Navigation'"
            matTooltipPosition="right">
      <fa-icon [icon]="isCollapsed ? 'chevron-right' : 'chevron-left'"
               [ngStyle]="{'margin-right': isCollapsed ? '0' : '3px'}"></fa-icon>
    </button>
  </div>
</div>
