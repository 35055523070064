import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { InstantErrorMatcher } from '../../instant-error-matcher';

@Component({
  selector: 'mtn-text-area-field',
  templateUrl: './text-area-field.component.html',
  styleUrls: ['./text-area-field.component.scss']
})
export class TextAreaFieldComponent implements OnInit {

  @Input()
  form: FormGroup;
  @Input()
  controlName: string;
  @Input()
  maxLength: number = 255;
  @Input()
  placeholder: string;

  instantErrorMatcher: ErrorStateMatcher = new InstantErrorMatcher();

  constructor() {
  }

  ngOnInit() {
  }

}
