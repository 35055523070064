import { Component, OnInit } from '@angular/core';
import { UserPreferencesService } from '../../../core/user-preferences/user-preferences.service';
import { BaseComponent } from '../../../core/base-component';
import { Store as NgrxStore } from '@ngrx/store';
import { AppState } from '../../../app-state';
import { selectUserState, UserState } from '../../../auth/user-state';
import { UserPreferences } from '../../../core/user-preferences/user-preferences';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserSetUserPreferences } from '../../../auth/user-actions';
import * as _ from 'lodash';
import { MapMode } from '../../../map/map-mode.enum';
import { MtnMapOptions } from '../../../map/mtn-map-options';
import { buildDefaultMapSnapshot } from '../../../map/map-snapshot';
import { KeyIndicatorType } from '../../../core/user-preferences/key-indicator-type.enum';
import { SalesVolumeDisplayType } from '../../../core/user-preferences/sales-volume-display-type.enum';
import { SalesSqftDisplayType } from '../../../core/user-preferences/sales-sqft-display-type.enum';
import { StoreVolume } from '../../../core/store/volume/store-volume';
import { Space, Store } from '../../../core/models';
import { ToastService } from '../../../core/toast/toast.service';
import { MtnMap } from '../../../map/mtn-map';
import { FilterGroup } from '../../../core/federation/filter/filter-group';
import MapTypeId = google.maps.MapTypeId;

@Component({
  selector: 'mtn-profile-preferences-tab',
  templateUrl: './profile-preferences-tab.component.html',
  styleUrls: ['./profile-preferences-tab.component.scss']
})
export class ProfilePreferencesTabComponent extends BaseComponent implements OnInit {

  form: FormGroup;
  KeyIndicatorType = KeyIndicatorType;
  MapMode = MapMode;
  MapTypeId = MapTypeId;
  mapOptions: MtnMapOptions;
  preferences: UserPreferences;
  SalesVolumeDisplayModeType = SalesVolumeDisplayType;
  SalesSqftDisplayModeType = SalesSqftDisplayType;
  sampleStore: Store;
  sampleVolume: StoreVolume;

  private map: MtnMap;

  constructor(private store: NgrxStore<AppState>,
              private toaster: ToastService,
              private userPreferencesService: UserPreferencesService) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToAuthState();
  }

  onMapReady(map: MtnMap): void {
    this.map = map;
  }

  private buildRequest(): UserPreferences {
    const request: UserPreferences = _.cloneDeep(this.preferences);
    const formValue = this.form.getRawValue();

    Object.assign(request, formValue);

    return request;
  }

  private initForm(): void {
    this.form = new FormGroup({
      mapDefaultMode: new FormControl(this.preferences.mapDefaultMode, [Validators.required]),
      mapDefaultType: new FormControl(this.preferences.mapDefaultType, [Validators.required]),
      primaryKeyIndicator: new FormControl(this.preferences.primaryKeyIndicator, [Validators.required]),
      salesSqftDisplayMode: new FormControl(this.preferences.salesSqftDisplayMode, [Validators.required]),
      salesVolumeDisplayMode: new FormControl(this.preferences.salesVolumeDisplayMode, [Validators.required])
    });

    this.sampleVolume = new StoreVolume({
      salesArea: 50000,
      total: 300000,
      totalArea: 75000
    });

    this.sampleStore = new Store({
      salesArea: 50000,
      space: new Space({
        area: 75000
      })
    });

    this.subscribeToFormChanges();
  }

  private save(): void {
    const request = this.buildRequest();
    this.userPreferencesService.updateOne(request)
      .subscribe((result: UserPreferences) => {
        this.store.dispatch(UserSetUserPreferences({preferences: result}));
        this.toaster.info('Successfully updated preferences');
      });
  }

  private subscribeToAuthState(): void {
    this.addSubscription(
      selectUserState(this.store)
        .subscribe((state: UserState) => {
          this.preferences = state.userPreferences;
          if (this.preferences && !this.form) {
            this.initForm();
          }
          if (this.preferences && !this.mapOptions) {
            const snapshot = buildDefaultMapSnapshot(this.preferences.mapDefaultMode, this.preferences.mapDefaultType);
            const options = snapshot.options;
            options.key = 'preferences-map-2';
            options.filterGroup = new FilterGroup();
            options.isAutoSaveEnabled = false;
            options.isAutoUpdateEnabled = false;
            options.isFeatureMap = false;
            options.controlConfiguration.isGoogleSearchEnabled = false;
            options.controlConfiguration.isFiltersEnabled = false;
            options.controlConfiguration.isMapModeEnabled = false;
            options.controlConfiguration.isMapTypeEnabled = false;
            options.controlConfiguration.isRecenterEnabled = false;
            options.controlConfiguration.isStreetViewEnabled = false;
            options.controlConfiguration.isTiltAndRotateEnabled = false;
            options.controlConfiguration.isZoomEnabled = false;

            this.mapOptions = options;
          }
        })
    );
  }

  private subscribeToFormChanges(): void {
    this.addSubscription(
      this.form.valueChanges
        .subscribe((value: any) => {
          if (this.map) {
            this.map.setOptions({
              mode: value.mapDefaultMode,
              mapTypeId: value.mapDefaultType
            });
          }
          this.save();
        })
    )
  }

}
