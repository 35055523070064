import { Component, OnInit } from '@angular/core';
import { AuthorizationAwareComponent } from '../../../core/authorization-aware-component';
import { AppState } from '../../../app-state';
import { Store as NgrxStore } from '@ngrx/store';
import { Company } from '../../../core/company/company';
import { CompanyService } from '../../../core/company/company.service';
import { finalize } from 'rxjs/operators';
import { UpgradeLicenseWizard } from '../../../core/license/upgrade-license-wizard/upgrade-license-wizard';
import { WizardRunnerService } from '../../../core/wizard/wizard-runner.service';

@Component({
  selector: 'mtn-self-service-administration',
  templateUrl: './self-service-administration.component.html',
  styleUrls: ['./self-service-administration.component.scss']
})
export class SelfServiceAdministrationComponent extends AuthorizationAwareComponent implements OnInit {

  company: Company;
  isLoading = false;

  constructor(private companyService: CompanyService,
              protected ngrxStore: NgrxStore<AppState>,
              private wizardRunner: WizardRunnerService) {
    super(ngrxStore);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  onAuthorizationChange(): void {
    if (!this.company && this.currentCompany) {
      this.company = this.currentCompany;
    }
  }

  onAuthorizationInit(): void {
  }

  loadCompany(): void {
    if (!this.isLoading) {
      this.isLoading = true;
      this.companyService.findOne(this.currentUser.company.uuid)
        .pipe(finalize(() => this.isLoading = false))
        .subscribe((result: Company) => {
          this.company = result;
        });
    }
  }

  openUpgradeLicenseWizard(): void {
    const wizard = new UpgradeLicenseWizard();
    this.wizardRunner.run(wizard);
  }

}
