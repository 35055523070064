import { Component } from '@angular/core';
import { WizardPageComponent } from '../../../../../core/wizard/wizard-page-component';
import { RequestMoreStoreInsightWizard } from '../request-more-store-insight-wizard';
import { Observable } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '../../../../../core/toast/toast.service';
import { ConsultingService } from '../../../../../core/consulting/consulting.service';
import { StoreInsightRequest } from '../../../../../core/consulting/store-insight-request';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'mtn-request-more-store-insight-form-page',
  templateUrl: './request-more-store-insight-form-page.component.html',
  styleUrls: ['./request-more-store-insight-form-page.component.scss']
})
export class RequestMoreStoreInsightFormPageComponent extends WizardPageComponent<RequestMoreStoreInsightWizard> {

  key = 'request-more-store-insight-form';

  constructor(private consultingService: ConsultingService,
              private toaster: ToastService) {
    super();
    this.title = 'Request More Insight';
    this.closeButtonText = 'Cancel';
    this.nextButtonText = 'Submit';
  }

  onLoad(): Observable<any> {
    this.initForm();
    return super.onLoad();
  }

  onNext(): Observable<string> {
    const formValue = this.form.getRawValue();
    const requestedReports: string[] = [];
    if (formValue.assetReview) {
      requestedReports.push('Asset Review');
    }
    if (formValue.groceryPotentialStudy) {
      requestedReports.push('Grocery Potential Study');
    }
    if (formValue.salesForecastReport) {
      requestedReports.push('Sales Forecast Report');
    }

    const userProfile = _.cloneDeep(this.wizard.model.userProfile);
    if (formValue.phoneNumber && formValue.phoneNumber !== userProfile.phoneNumber) {
      userProfile.phoneNumber = formValue.phoneNumber;
    }

    const request: StoreInsightRequest = {
      company: this.wizard.model.userProfile.company,
      message: formValue.message,
      requestedReports: requestedReports,
      store: this.wizard.model.store,
      userProfile: userProfile
    };

    return this.consultingService.requestStoreInsight(request)
      .pipe(map(() => {
        this.toaster.info('Successfully submitted request');
        return null;
      }));
  }

  private initForm(): void {
    this.form = new FormGroup({
      groceryPotentialStudy: new FormControl(false),
      assetReview: new FormControl(false),
      salesForecastReport: new FormControl(false),
      other: new FormControl(false),
      email: new FormControl(this.wizard.model.userProfile.email, [Validators.email, Validators.required]),
      phoneNumber: new FormControl(this.wizard.model.userProfile.phoneNumber, [Validators.minLength(10), Validators.maxLength(10)]),
      message: new FormControl(null, [Validators.maxLength(500)])
    });

    this.form.get('email').disable();
  }

}
