<div class="map-search-container container" fxFill>
  <mtn-map [options]="options" (ready)="onMapReady($event)" *ngIf="options"></mtn-map>
  <div class="mtn-data-zoom-warning" fxLayout="row" fxLayoutAlign="center center"
       *ngIf="map && isFeatureLayerEnabled && map.getZoom() <= 7">
    <div class="warning-container" fxLayout="row" fxLayoutGap="5px">
      <fa-icon icon="exclamation-triangle"></fa-icon>
      <strong class="color-primary">Zoom in to load MTN Data.</strong>
    </div>
  </div>
  <mtn-store-spotlight-panel #storeSpotlightPanel [map]="map"
                             *ngIf="map?.selections.size === 1"></mtn-store-spotlight-panel>
  <mtn-multi-select-spotlight-panel [map]="map" *ngIf="map?.selections.size > 1"></mtn-multi-select-spotlight-panel>
</div>

