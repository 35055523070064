import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'mtn-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss']
})
export class TextFieldComponent implements OnInit {

  @Input()
  form: FormGroup;
  @Input()
  controlName: string;
  @Input()
  enableClear = false;
  @Input()
  isRequired = false;
  @Input()
  label: string;
  @Input()
  noErrorPadding = false;
  @Input()
  placeholder: string;

  constructor() {
  }

  ngOnInit() {
  }
}
