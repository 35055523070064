import { Component, Input } from '@angular/core';
import { Company } from '../../company/company';
import { UserProfile } from '../../user-profile/user-profile';

@Component({
  selector: 'mtn-hubspot-object-link',
  templateUrl: './hubspot-object-link.component.html',
  styleUrls: ['./hubspot-object-link.component.scss']
})
export class HubspotObjectLinkComponent {

  @Input()
  company: Company;
  @Input()
  contact: UserProfile;

  constructor() {
  }

}
