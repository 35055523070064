import { Component } from '@angular/core';
import { WizardPageComponent } from '../../../wizard/wizard-page-component';
import { EditUserWizard } from '../edit-user-wizard';
import { UserProfileService } from '../../../user-profile/user-profile.service';
import { ToastService } from '../../../toast/toast.service';
import { Observable, of } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MtnValidators } from '../../../form-controls/mtn-validators';
import { UserProfile } from '../../../user-profile/user-profile';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';

@Component({
  selector: 'mtn-edit-user-page',
  templateUrl: './edit-user-page.component.html',
  styleUrls: ['./edit-user-page.component.scss']
})
export class EditUserPageComponent extends WizardPageComponent<EditUserWizard> {

  key = 'edit-user';

  constructor(private userService: UserProfileService,
              private toaster: ToastService) {
    super();
  }

  onLoad(): Observable<any> {
    this.closeButtonText = 'Cancel';
    this.nextButtonText = 'Submit';
    this.title = 'Edit User';
    this.headerText = 'Edit User';

    this.form = new FormGroup({
      email: new FormControl(this.wizard.model.user.email, [Validators.email, Validators.required, Validators.maxLength(255)]),
      firstName: new FormControl(this.wizard.model.user.firstName, [Validators.maxLength(64)]),
      lastName: new FormControl(this.wizard.model.user.lastName, [Validators.maxLength(64)]),
      title: new FormControl(this.wizard.model.user.title, [Validators.maxLength(64)]),
      phoneNumber: new FormControl(this.wizard.model.user.phoneNumber, [MtnValidators.lengthEquals(10)])
    });

    return of(null);
  }

  onNext(): Observable<string> {
    const request = this.buildRequest();

    return this.userService.updateOne(request)
      .pipe(map((result: UserProfile) => {
        this.toaster.info("Successfully updated User");
        this.wizard.model.user = result;
        this.wizard.model.isEdited = true;
        return null;
      }));
  }

  private buildRequest(): UserProfile {
    const request = _.cloneDeep(this.wizard.model.user);
    Object.assign(request, this.form.getRawValue());
    return request;
  }

}
