import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '../../models';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'mtn-other-stores-table',
  templateUrl: './other-stores-table.component.html',
  styleUrls: ['./other-stores-table.component.scss']
})
export class OtherStoresTableComponent implements OnInit {

  @Output()
  onLinkClick = new EventEmitter<Store>();

  @Input()
  isDefaultBehaviorDisabled = false;
  @Input()
  otherStores: Store[] = [];

  @ViewChild(MatSort)
  sort: MatSort;

  dataSource = new MatTableDataSource<Store>();
  displayedColumns: string[] = ['link', 'name', 'status', 'observedDate'];

  constructor() {
  }

  ngOnInit(): void {
    this.initTable();
  }

  private initTable(): void {
    setTimeout(() => {
      this.sort.disableClear = true;
      this.dataSource.sort = this.sort;
    }, 50);

    this.dataSource.sortingDataAccessor = (store: Store, header: string): any => {
      let value = null;

      if (header === 'name') {
        let name = store.name;
        if (store.number) {
          name += ` - ${store.number}`;
        }
        return name;
      } else if (header === 'status') {
        return store.getCurrentStatus().getStatusSystemName();
      } else if (header === 'observedDate') {
        return store.getCurrentStatus().statusDate;
      }

      return value;
    };

    this.dataSource.data = this.otherStores;
  }

}
