<div class="comparison-detail-page-container container" fxLayout="column" fxLayoutGap="15px">
  <form [formGroup]="form" fxLayout="row" fxLayoutAlign="space-between">
    <div class="form-container-left" fxLayout="row" fxLayoutGap="15px">
      <mtn-key-indicator-field [form]="form"></mtn-key-indicator-field>
    </div>
    <div class="form-container-right" fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="15px">
      <mtn-comparison-type-field [form]="form"></mtn-comparison-type-field>
      <mtn-region-field [form]="form" [isMarketAreaAvailable]="!!marketArea?.getFeature()"></mtn-region-field>
      <mtn-drive-time-minutes-field [form]="form"
                                    *ngIf="form.get('region').value === RegionType.DRIVE_TIME"></mtn-drive-time-minutes-field>
      <mtn-ring-miles-field [form]="form"
                            *ngIf="form.get('region').value === RegionType.RING"></mtn-ring-miles-field>
    </div>
  </form>
  <div *ngIf="ranking && comparison?.isChartable(); else notChartable">
    <div class="widget-container container" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
      <div class="chart-container container">
        <canvas height="100px" width="100px" mdbChart chartType="doughnut" [legend]="false" [colors]="chartColors"
                [datasets]="chartDataSets" [options]="chartOptions" [labels]="chartLabels"></canvas>
        <div class="percentile-container" fxLayout="column" fxLayoutAlign="center center">
          <small>{{labelPercentile}}</small>
          <small>Percentile</small>
        </div>
      </div>
      <div class="descriptor-container" fxLayout="column">
        <h2
          class="mat-h2 text-small-caps no-bottom-margin">{{rankingDescriptor}} {{rankingGroupDescriptor}} {{rankingTypeDescriptor}}</h2>
        <small class="color-muted" *ngIf="labelRegion">vs {{labelComparisonType}} in {{labelRegion}}</small>
        <div fxFlex></div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
          <span class="color-muted">Rank {{ranking.rank}} of {{comparison.getFilteredRankings().length}} Stores</span>
          <fa-icon icon="exclamation-triangle" style="color:orange"
                   *ngIf="comparison.rankings.length > comparison.getFilteredRankings().length"
                   matTooltipClass="multiline-tooltip" [matTooltip]="excludedStoresTooltip"></fa-icon>
        </div>
      </div>
    </div>
    <div fxLayout="column" fxLayoutGap="15px">
      <h3 class="mat-h3 text-small-caps">Competitive Distribution</h3>
      <div class="number-line-chart-container container" *ngFor="let dataset of datasets">
        <mtn-number-line-chart [dataset]="dataset" [axisBuilder]="numberChartAxisBuilder"
                               [showAxis]="true"></mtn-number-line-chart>
        <mtn-restricted-license-overlay *ngIf="wizard.model.isRestrictedLicenseOverlayEnabled" [bannerSize]="Size.SMALL"
                                        [buttonSize]="Size.SMALL"></mtn-restricted-license-overlay>
      </div>
    </div>
  </div>

  <!--  <mtn-simple-alert class="excluded-stores-alert" color="orange" icon="exclamation-triangle"-->
  <!--                    title="Store(s) Excluded from Ranking" [nonStandardSize]="true"-->
  <!--                    text="X stores were excluded from the ranking due to missing data. This typically occurs when the store is in a rural area, or represents an insignificant impact to the market.">-->
  <!--  </mtn-simple-alert>-->
  <mtn-spinner [on]="isLoading" [size]="SpinnerSize.LARGE"></mtn-spinner>
</div>

<ng-template #notChartable>
  <div class="color-muted">{{comparison.unchartableReason}}</div>
</ng-template>
