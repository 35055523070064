import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseComponent } from '../../../base-component';
import { KeyIndicatorType } from '../../../user-preferences/key-indicator-type.enum';

@Component({
  selector: 'mtn-banner-comparison-mode-select',
  templateUrl: './banner-comparison-mode-select.component.html',
  styleUrls: ['./banner-comparison-mode-select.component.scss']
})
export class BannerComparisonModeSelectComponent extends BaseComponent {

  @Input()
  form: FormGroup;
  @Input()
  controlName: string = 'keyIndicator';
  @Input()
  placeholder: string = 'Key Indicator';

  KeyIndicatorType = KeyIndicatorType;

  constructor() {
    super();
  }

}
