import { Component } from '@angular/core';
import { WizardPageComponent } from '../../../../core/wizard/wizard-page-component';
import { AddOrEditSavedSearchWizard } from '../add-or-edit-saved-search-wizard';
import { Observable } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SavedSearchService } from '../../../../core/federation/filter/saved-search.service';
import { map } from 'rxjs/operators';
import { SavedSearch } from '../../../../core/federation/filter/saved-search';
import { ToastService } from '../../../../core/toast/toast.service';
import * as _ from 'lodash';
import { FilterGroup } from '../../../../core/federation/filter/filter-group';
import { Filter } from '../../../../core/federation/filter/filter';

@Component({
  selector: 'mtn-add-or-edit-saved-search-page',
  templateUrl: './add-or-edit-saved-search-page.component.html',
  styleUrls: ['./add-or-edit-saved-search-page.component.scss']
})
export class AddOrEditSavedSearchPageComponent extends WizardPageComponent<AddOrEditSavedSearchWizard> {

  key = 'add-or-edit-saved-search';

  constructor(private savedSearchService: SavedSearchService,
              private toaster: ToastService) {
    super();
    this.title = 'Create a Saved Search';
    this.closeButtonText = 'Cancel';
    this.nextButtonText = 'Save';
  }

  onLoad(): Observable<any> {
    if (this.wizard.model.savedSearch?.uuid) {
      this.title = 'Edit Saved Search';
    }

    this.initForm();
    return super.onLoad();
  }

  onNext(): Observable<string> {
    const request = this.buildRequest();
    return this.savedSearchService.addOne(request)
      .pipe(map((result: SavedSearch) => {
        this.toaster.info('Successfully created Saved Search');
        this.wizard.model.result = result;
        return null;
      }));
  }

  private buildRequest(): SavedSearch {
    let request: SavedSearch;
    if (this.wizard.model.savedSearch) {
      request = _.cloneDeep(this.wizard.model.savedSearch);
    } else {
      request = new SavedSearch();
      request.featureType = this.wizard.model.map.options.featureType;
      request.isSupport = false;
      request.filterGroup = this.copyMapFilterGroup();
    }

    const formValue = this.form.getRawValue();
    request.name = formValue.name;
    request.description = formValue.description;

    return request;
  }

  private copyMapFilterGroup(): FilterGroup {
    const copy = _.cloneDeep(this.wizard.model.map.options.filterGroup);
    copy.id = null;
    copy.uuid = null;

    copy.filters.forEach((filter: Filter<any>) => {
      filter.id = null;
      filter.uuid = null;
    });

    return copy;
  }

  private initForm(): void {
    const existing = this.wizard.model.savedSearch;

    this.form = new FormGroup({
      name: new FormControl(existing ? existing.name : null, [Validators.maxLength(64), Validators.required]),
      description: new FormControl(existing ? existing.description : null, [Validators.maxLength(500)])
    });
  }

}
