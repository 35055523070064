import { Wizard } from '../../../../core/wizard/wizard';
import { StoreComparison } from '../../../../core/store/ranking/store-comparison';
import { AddComparisonPageComponent } from './add-comparison-page/add-comparison-page.component';
import { FeatureCollection, Store } from '../../../../core/models';
import { UserPreferences } from '../../../../core/user-preferences/user-preferences';

export class AddComparisonWizard extends Wizard<AddComparisonWizardModel> {
  key = 'add-comparison';
  pageTypeMap = {
    'add-comparison': AddComparisonPageComponent
  };
  startingPageKey = 'add-comparison';
}

export interface AddComparisonWizardModel {
  comparison?: StoreComparison;
  marketArea: FeatureCollection;
  store: Store;
  userPreferences: UserPreferences;
}
