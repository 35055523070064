import { Component } from '@angular/core';
import { WizardPageComponent } from '../../../core/wizard/wizard-page-component';
import { CloneCollectionWizard } from '../clone-collection-wizard';
import { Observable, of } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { Collection } from '../../../core/federation/collection/collection';
import { CollectionService } from '../../../core/federation/collection/collection.service';
import { ToastService } from '../../../core/toast/toast.service';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'mtn-clone-collection-page',
  templateUrl: './clone-collection-page.component.html',
  styleUrls: ['./clone-collection-page.component.scss']
})
export class CloneCollectionPageComponent extends WizardPageComponent<CloneCollectionWizard> {

  key = 'clone-collection';

  constructor(private collectionService: CollectionService,
              private toaster: ToastService) {
    super();
    this.title = 'Clone Collection';
    this.nextButtonText = 'Submit';
    this.closeButtonText = 'Cancel';
  }

  onLoad(): Observable<any> {
    this.initForm();
    return super.onLoad();
  }

  onNext(): Observable<string> {
    const request = this.buildRequest();

    return this.collectionService.addOne(request)
      .pipe(switchMap((result: Collection) => {
        this.wizard.model.result = result;

        const formValue = this.form.getRawValue();
        if (formValue.favorite) {
          return this.collectionService.addOneFavorite(result.uuid)
            .pipe(map(() => {
              this.wizard.model.result.favoritedBy.push(this.wizard.model.userProfileUuid);
              this.toaster.info('Successfully cloned collection');
              return null;
            }));
        } else {
          this.toaster.info('Successfully cloned collection');
          return of(null);
        }
      }));
  }

  private buildRequest(): Collection {
    const request = _.cloneDeep(this.wizard.model.collection);
    request.id = null;
    request.uuid = null;
    request.owner = null; //Will be set automatically by the back-end
    request.shares = [];
    request.favoritedBy = [];

    if (request.savedSearch) {
      request.savedSearch.id = null;
      request.savedSearch.uuid = null;

      request.savedSearch.filterGroup.anonymize();
    }

    const formValue = this.form.getRawValue();
    request.name = formValue.name;
    request.description = formValue.description;

    return request;
  }

  private initForm(): void {
    const isFavorite = this.wizard.model.collection ? this.wizard.model.collection.isFavoritedBy(this.wizard.model.userProfileUuid) : false;

    const cloneName = `${this.wizard.model.collection.name} (Copy)`;

    this.form = new FormGroup({
      description: new FormControl(this.wizard.model.collection?.description, [Validators.maxLength(255)]),
      name: new FormControl(cloneName, [Validators.maxLength(64), Validators.required]),
      favorite: new FormControl(isFavorite)
    });
  }

}
