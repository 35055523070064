import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FilterToolbarState } from '../filter-toolbar-state';
import { FormControl, FormGroup } from '@angular/forms';
import { Options } from '@angular-slider/ngx-slider';
import { AuthorizationAwareComponent } from '../../../core/authorization-aware-component';
import { AppState } from '../../../app-state';
import { Store as NgrxStore } from '@ngrx/store';
import { SalesVolumeDisplayType } from '../../../core/user-preferences/sales-volume-display-type.enum';
import { EXCLUSIVE_FILTER_DEFINITION, formatNumericalShorthand } from '../../../core/util/string-utils';
import { SalesSqftDisplayType } from '../../../core/user-preferences/sales-sqft-display-type.enum';
import { SalesVolumeViewComponent } from '../../../core/store/sales-volume-view/sales-volume-view.component';
import { MtnMap } from '../../mtn-map';
import { FilterType } from '../../../core/federation/filter/filter-type.enum';
import * as _ from 'lodash';
import { SalesSqftFilter, SalesVolumeFilter } from '../../../core/federation/filter/filter';
import { NumberRange } from '../../../core/federation/number-range';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'mtn-sales-filters-assembly',
  templateUrl: './sales-filters-assembly.component.html',
  styleUrls: ['./sales-filters-assembly.component.scss']
})
export class SalesFiltersAssemblyComponent extends AuthorizationAwareComponent implements OnChanges, OnInit {

  @Input()
  map: MtnMap;
  @Input()
  toolbarState: FilterToolbarState;

  EXCLUSIVE_FILTER_DEFINITION = EXCLUSIVE_FILTER_DEFINITION;
  isMenuReady = false;
  salesSqftConfig: Options;
  salesSqftForm = new FormGroup({
    isExclusive: new FormControl(),
    salesSqft: new FormControl()
  });
  salesSqftSuffix: string;
  salesVolumeConfig: Options;
  salesVolumeForm = new FormGroup({
    isExclusive: new FormControl(),
    salesVolume: new FormControl()
  });
  salesVolumeSuffix: string;

  constructor(protected ngrxStore: NgrxStore<AppState>) {
    super(ngrxStore);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.toolbarState && this.salesSqftConfig && this.salesVolumeConfig) {
      this.updateForm();
    }
  }

  onAuthorizationChange(): void {
  }

  onAuthorizationInit(): void {
    this.initSuffixes();
    this.initSliderConfigs();
    this.updateForm();
    this.subscribeToFormChanges();
  }

  clearSalesSqft(): void {
    this.salesSqftForm.get('salesSqft').setValue([this.salesSqftConfig.floor, this.salesSqftConfig.ceil]);
    this.salesSqftForm.get('isExclusive').setValue(false);
  }

  clearSalesVolume(): void {
    this.salesVolumeForm.get('salesVolume').setValue([this.salesVolumeConfig.floor, this.salesVolumeConfig.ceil]);
    this.salesVolumeForm.get('isExclusive').setValue(false);
  }

  hideSliders(): void {
    this.isMenuReady = false;
  }

  /**
   * Due to a canvas-like issue, where the menu appears open, but is still drawing and rendering its contents, if we
   * don't wait a bit to show the sliders, they don't fill the full width of the menu (likely because we're exceeding
   * angular-material's default max width for menus).
   */
  showSliders(): void {
    setTimeout(() => {
      this.isMenuReady = true;
    }, 300);
  }

  private initSliderConfigs(): void {
    this.initSalesSqftSliderConfig();
    this.initSalesVolumeSliderConfig();
  }

  private initSalesSqftSliderConfig(): void {
    const isAnnualDisplay = this.userPreferences.salesVolumeDisplayMode === SalesVolumeDisplayType.ANNUAL;

    const maxSalesSqft = isAnnualDisplay ? 5000 : 100;
    this.salesSqftConfig = {
      ceil: maxSalesSqft,
      floor: 0,
      step: isAnnualDisplay ? 100 : 1,
      tickStep: isAnnualDisplay ? 500 : 10,
      showTicks: true,
      translate: (value: number): string => {
        const suffix = value === maxSalesSqft ? '+' : '';
        return `$${formatNumericalShorthand(value)}${suffix}`;
      }
    };

    this.salesSqftForm.get('salesSqft').setValue([0, maxSalesSqft], {emitEvent: false});
  }

  private initSalesVolumeSliderConfig(): void {
    const isAnnualDisplay = this.userPreferences.salesVolumeDisplayMode === SalesVolumeDisplayType.ANNUAL;

    const maxVolume = isAnnualDisplay ? 100000000 : 2000000;
    this.salesVolumeConfig = {
      ceil: maxVolume,
      floor: 0,
      step: isAnnualDisplay ? 1000000 : 10000,
      tickStep: isAnnualDisplay ? 10000000 : 200000,
      showTicks: true,
      translate: (value: number): string => {
        const suffix = value === maxVolume ? '+' : '';
        return `$${formatNumericalShorthand(value)}${suffix}`;
      }
    };

    this.salesVolumeForm.get('salesVolume').setValue([0, maxVolume], {emitEvent: false});
  }

  private initSuffixes(): void {
    const isAnnualDisplay = this.userPreferences.salesVolumeDisplayMode === SalesVolumeDisplayType.ANNUAL;
    const isSalesAreaDisplay = this.userPreferences.salesSqftDisplayMode === SalesSqftDisplayType.SALES_AREA;

    this.salesVolumeSuffix = isAnnualDisplay ? SalesVolumeViewComponent.SUFFIX_ANNUAL : SalesVolumeViewComponent.SUFFIX_WEEKLY;
    this.salesSqftSuffix = isSalesAreaDisplay ? 'Sales Area' : 'Total Area';
  }

  private subscribeToFormChanges(): void {
    this.subscribeToSalesSqftFormChanges();
    this.subscribeToSalesVolumeFormChanges();
  }

  private subscribeToSalesSqftFormChanges(): void {
    this.addSubscription(
      this.salesSqftForm.valueChanges
        .pipe(debounceTime(300))
        .subscribe((value: any) => {
          const min = value.salesSqft ? value.salesSqft[0] : this.salesSqftConfig.floor;
          const max = value.salesSqft ? value.salesSqft[1] : this.salesSqftConfig.ceil;
          const isExclusive = value.isExclusive;

          const isMinEqualToFloor = min === this.salesSqftConfig.floor;
          const isMaxEqualToFloor = max === this.salesSqftConfig.ceil;

          const filterGroup = _.cloneDeep(this.map.options.filterGroup);

          if (!isMinEqualToFloor || !isMaxEqualToFloor || isExclusive) {
            const range = new NumberRange();
            range.min = min;
            range.max = max;

            //If the map has a SALES_SQFT filter, we need to update it
            let filter = filterGroup.getOwnFilter(FilterType.SALES_SQFT);
            //Else, we need to add one
            if (!filter) {
              filter = new SalesSqftFilter();
            }

            filter.value = range;
            filter.isExclusive = isExclusive;

            filterGroup.clearFilter(FilterType.SALES_SQFT);
            filterGroup.filters.push(filter);
            this.map.setOptions({
              filterGroup: filterGroup
            });
          } else {
            //If the map has a SALES_SQFT filter, we need to remove it
            if (filterGroup.hasOwnFilter(FilterType.SALES_SQFT)) {
              filterGroup.clearFilter(FilterType.SALES_SQFT);
              this.map.setOptions({
                filterGroup: filterGroup
              });
            }
            //Else, we don't need to do anything
          }
        })
    );
  }

  private subscribeToSalesVolumeFormChanges(): void {
    this.addSubscription(
      this.salesVolumeForm.valueChanges
        .pipe(debounceTime(300))
        .subscribe((value: any) => {
          const min = value.salesVolume ? value.salesVolume[0] : this.salesVolumeConfig.floor;
          const max = value.salesVolume ? value.salesVolume[1] : this.salesVolumeConfig.ceil;
          const isExclusive = value.isExclusive;

          const isMinEqualToFloor = min === this.salesVolumeConfig.floor;
          const isMaxEqualToFloor = max === this.salesVolumeConfig.ceil;

          const filterGroup = _.cloneDeep(this.map.options.filterGroup);

          if (!isMinEqualToFloor || !isMaxEqualToFloor || isExclusive) {
            const range = new NumberRange();
            range.min = min;
            range.max = max;

            //If the map has a SALES_VOLUME filter, we need to update it
            let filter = filterGroup.getOwnFilter(FilterType.SALES_VOLUME);
            //Else, we need to add one
            if (!filter) {
              filter = new SalesVolumeFilter();
            }

            filter.value = range;
            filter.isExclusive = isExclusive;

            filterGroup.clearFilter(FilterType.SALES_VOLUME);
            filterGroup.filters.push(filter);
            this.map.setOptions({
              filterGroup: filterGroup
            });
          } else {
            //If the map has a SALES_VOLUME filter, we need to remove it
            if (filterGroup.hasFilter(FilterType.SALES_VOLUME)) {
              filterGroup.clearFilter(FilterType.SALES_VOLUME);
              this.map.setOptions({
                filterGroup: filterGroup
              });
            }
            //Else, we don't need to do anything
          }
        })
    );
  }

  private updateForm(): void {
    this.updateSalesSqftFormControl();
    this.updateSalesVolumeFormControl();
  }

  private updateSalesSqftFormControl(): void {
    let minValue = 0;
    let maxValue = this.salesSqftConfig.ceil;

    if (this.toolbarState.filterSalesSqft?.value?.min) {
      minValue = this.toolbarState.filterSalesSqft.value.min;
    }
    if (this.toolbarState.filterSalesSqft?.value?.max) {
      maxValue = this.toolbarState.filterSalesSqft.value.max;
    }

    this.salesSqftForm.get('salesSqft').setValue([minValue, maxValue], {emitEvent: false});
    this.salesSqftForm.get('isExclusive').setValue(this.toolbarState.filterSalesSqft?.isExclusive, {emitEvent: false});
  }

  private updateSalesVolumeFormControl(): void {
    let minValue = 0;
    let maxValue = this.salesVolumeConfig.ceil;

    if (this.toolbarState.filterSalesVolume?.value?.min) {
      minValue = this.toolbarState.filterSalesVolume.value.min;
    }
    if (this.toolbarState.filterSalesVolume?.value?.max) {
      maxValue = this.toolbarState.filterSalesVolume.value.max;
    }

    this.salesVolumeForm.get('salesVolume').setValue([minValue, maxValue], {emitEvent: false});
    this.salesVolumeForm.get('isExclusive').setValue(this.toolbarState.filterSalesVolume?.isExclusive, {emitEvent: false});
  }

}
