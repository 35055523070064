import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MtnMap } from '../../mtn-map';
import { FilterToolbarState } from '../filter-toolbar-state';
import { AuthorizationAwareComponent } from '../../../core/authorization-aware-component';
import { AppState } from '../../../app-state';
import { Store as NgrxStore } from '@ngrx/store';
import { FormControl, FormGroup } from '@angular/forms';
import { DefinitionService } from '../../../core/definition/definition.service';
import { take } from 'rxjs/operators';
import { Definition } from '../../../core/definition/definition';
import { FitType } from '../../../core/store/fit/fit-type.enum';
import * as _ from 'lodash';
import { FilterType } from '../../../core/federation/filter/filter-type.enum';
import { FitCategoryFilter } from '../../../core/federation/filter/filter';
import { EXCLUSIVE_FILTER_DEFINITION } from '../../../core/util/string-utils';

@Component({
  selector: 'mtn-fit-filter-assembly',
  templateUrl: './fit-filter-assembly.component.html',
  styleUrls: ['./fit-filter-assembly.component.scss']
})
export class FitFilterAssemblyComponent extends AuthorizationAwareComponent implements OnChanges, OnInit {

  @Input()
  map: MtnMap;
  @Input()
  toolbarState: FilterToolbarState;

  EXCLUSIVE_FILTER_DEFINITION = EXCLUSIVE_FILTER_DEFINITION;
  isAllSelected = false;
  options: Definition<FitType>[] = [];
  form: FormGroup;

  constructor(private definitionService: DefinitionService,
              protected ngrxStore: NgrxStore<AppState>) {
    super(ngrxStore);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.toolbarState && this.form) {
      this.updateForm();
    }
  }

  onAuthorizationChange(): void {
  }

  onAuthorizationInit(): void {
    this.initForm();
  }

  clear(): void {
    const formState = this.buildFormState(false);
    this.form.setValue(formState);
  }

  toggleSelectAll(): void {
    const newFormValue = !this.isAllSelected;
    const formState = this.buildFormState(newFormValue);
    this.form.setValue(formState);
  }

  private buildFormState(value: boolean): any {
    const formValue = this.form.getRawValue();
    let formState = {
      isExclusive: !!formValue.isExclusive
    };
    this.options.forEach((option: Definition<FitType>) => {
      // @ts-ignore
      formState[option.systemName] = value;
    });
    return formState;
  }

  private initForm(): void {
    this.definitionService.findAllFits()
      .pipe(take(1))
      .subscribe((definitions: Definition<FitType>[]) => {
        this.options = _.orderBy(definitions, 'displayName');

        let controls = {
          isExclusive: new FormControl()
        };

        definitions.forEach((definition: Definition<any>) => {
          // @ts-ignore
          controls[definition.systemName] = new FormControl();
        });

        this.form = new FormGroup(controls);

        this.updateForm();
        this.subscribeToFormChanges();
      });
  }

  private subscribeToFormChanges(): void {
    this.addSubscription(
      this.form.valueChanges.subscribe((formValue: any) => {
        const filterGroup = _.cloneDeep(this.map.options.filterGroup);
        const isExclusive = formValue.isExclusive;

        const enabledTypes: FitType[] = [];

        this.options.forEach((option: Definition<FitType>) => {
          if (formValue[option.systemName]) {
            enabledTypes.push(option.systemName);
          }
        });

        if (enabledTypes.length) {
          //If the map has a FIT_CATEGORY filter, we need to update it
          let filter = filterGroup.getOwnFilter(FilterType.FIT_CATEGORY);
          //Else, we need to add one
          if (!filter) {
            filter = new FitCategoryFilter();
          }

          filter.value = [...enabledTypes];
          filter.isExclusive = isExclusive;

          filterGroup.clearFilter(FilterType.FIT_CATEGORY);
          filterGroup.filters.push(filter);
          this.map.setOptions({
            filterGroup: filterGroup
          });
        } else {
          //If the map has a FIT_CATEGORY filter, we need to remove it
          if (filterGroup.hasOwnFilter(FilterType.FIT_CATEGORY)) {
            filterGroup.clearFilter(FilterType.FIT_CATEGORY);
            this.map.setOptions({
              filterGroup: filterGroup
            });
          }
          //Else, we don't need to do anything
        }
      })
    );
  }

  private updateForm(): void {
    const enabledTypes: FitType[] = this.toolbarState.filterFitCategories?.value;
    let isAllSelected = true;

    this.options.forEach((option: Definition<FitType>) => {
      const isEnabled = _.includes(enabledTypes, option.systemName);
      if (!isEnabled) {
        isAllSelected = false;
      }
      this.form.get(option.systemName).setValue(isEnabled, {emitEvent: false});
    });

    this.form.get('isExclusive').setValue(this.toolbarState.filterFitCategories?.isExclusive, {emitEvent: false});

    this.isAllSelected = isAllSelected;
  }
}
