import { Component, OnInit } from '@angular/core';
import { FeatureCollection, Store } from '../../../core/models';
import { Store as NgrxStore } from '@ngrx/store';
import { AppState } from '../../../app-state';
import { debounceTime, filter, finalize, take } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RegionType } from '../../../core/location/region-type.enum';
import { StoreComparison } from '../../../core/store/ranking/store-comparison';
import { forkJoin, Observable } from 'rxjs';
import { StorageService } from '../../../core/util/storage.service';
import { StoreComparisonService } from '../../../core/store/ranking/store-comparison.service';
import * as _ from 'lodash';
import { ComparisonType } from '../../../core/store/ranking/comparison-type.enum';
import { KeyIndicatorType } from '../../../core/user-preferences/key-indicator-type.enum';
import {
  DetailAddStoreComparisonAction,
  DetailAddStoreCustomComparisonRequestAction,
  DetailSetStoreCustomComparisonRequestsAction,
  DetailSetStoreCustomComparisonsAction
} from '../../detail-actions';
import { DetailState, selectDetailState } from '../../detail-state';
import { ToastService } from '../../../core/toast/toast.service';
import { WizardRunnerService } from '../../../core/wizard/wizard-runner.service';
import { AuthorizationAwareComponent } from '../../../core/authorization-aware-component';
import { UpgradeLicenseWizard } from '../../../core/license/upgrade-license-wizard/upgrade-license-wizard';
import { AddComparisonWizard } from './add-comparison-wizard/add-comparison-wizard';
import { NumberLineDataset } from '../../../core/chart/number-line-chart/number-line-dataset';
import { ChartAxisBuilder } from '../../../core/chart/number-line-chart/chart-axis-builder';
import { AxisMode } from '../../../core/chart/number-line-chart/AxisMode';
import { StoreVolume } from '../../../core/store/volume/store-volume';

@Component({
  selector: 'mtn-store-competitive-analysis-panel',
  templateUrl: './store-competitive-analysis-panel.component.html',
  styleUrls: ['./store-competitive-analysis-panel.component.scss']
})
export class StoreCompetitiveAnalysisPanelComponent extends AuthorizationAwareComponent implements OnInit {

  public readonly PANEL_DESCRIPTION = 'Customize how you compare this store against its competition in the store\'s local area. We\'ll keep track of the comparisons you find useful, and will show the same comparisons here going forward.';

  numberLineDatasets: NumberLineDataset[] = [];
  comparisonRequests: StoreComparison[] = [];
  comparisons: StoreComparison[] = [];
  form = new FormGroup({
    keyIndicator: new FormControl(null, [Validators.required]),
  });
  isAdding = false;
  isLoading = false;
  KeyIndicatorType = KeyIndicatorType;
  marketArea: FeatureCollection;
  RegionType = RegionType;
  store: Store;
  storeValue: number;
  storeValuePercentage: number;
  tradeArea: FeatureCollection;
  volume: StoreVolume;

  axisBuilder: ChartAxisBuilder;

  constructor(protected ngrxStore: NgrxStore<AppState>,
              private storageService: StorageService,
              private storeComparisonService: StoreComparisonService,
              private toaster: ToastService,
              private wizardRunner: WizardRunnerService) {
    super(ngrxStore);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onAuthorizationChange(): void {
  }

  onAuthorizationInit(): void {
    this.initFromAppState();
  }

  openAddComparisonWizard(): void {
    const wizard = new AddComparisonWizard();
    wizard.model = {
      marketArea: this.marketArea,
      store: this.store,
      userPreferences: this.userPreferences
    };

    this.wizardRunner.run(wizard)
      .afterClosed().subscribe((result: AddComparisonWizard) => {
      if (result.model.comparison) {
        const comparisonRequest = result.model.comparison;
        comparisonRequest.keyIndicator = this.form.get('keyIndicator').value;

        //Only add this comparison if it doesn't already exist
        const existingMatch = _.find(this.comparisonRequests, (comparison: StoreComparison) => {
          return comparison.equals(comparisonRequest);
        });
        if (existingMatch) {
          this.toaster.info('Comparison already exists');
        } else {
          this.comparisonRequests.push(comparisonRequest);

          this.storageService.set(StorageService.DETAIL_STORE_RANKING_COMPARISONS, this.comparisonRequests)
            .subscribe();

          this.ngrxStore.dispatch(DetailAddStoreCustomComparisonRequestAction({comparison: comparisonRequest}));

          this.storeComparisonService.findOne(comparisonRequest)
            .pipe(take(1))
            .pipe(finalize(() => this.isAdding = false))
            .subscribe((result: StoreComparison) => {
              this.toaster.info("Successfully added comparison");
              this.ngrxStore.dispatch(DetailAddStoreComparisonAction({comparison: result}));
            });
        }
      }
    });
  }

  openUpgradeLicenseWizard(): void {
    const wizard = new UpgradeLicenseWizard();
    this.wizardRunner.run(wizard);
  }

  removeComparison(comparison: StoreComparison): void {
    const filteredRequests = _.reject(this.comparisonRequests, (request: StoreComparison) => request.equals(comparison));
    this.storageService.set(StorageService.DETAIL_STORE_RANKING_COMPARISONS, filteredRequests).subscribe();
    this.comparisonRequests = filteredRequests;
    this.ngrxStore.dispatch(DetailSetStoreCustomComparisonRequestsAction({comparisons: filteredRequests}));

    const filteredComparisons = _.reject(this.comparisons, (existing: StoreComparison) => existing.equals(comparison));
    this.ngrxStore.dispatch(DetailSetStoreCustomComparisonsAction({comparisons: filteredComparisons}));

    this.toaster.info("Successfully removed comparison");
  }

  private applyComparisons(comparisons: StoreComparison[]): void {
    //Clone the comparisons to avoid immutable errors caused by pulling comparisons from ngrx state
    const comparisonClones = comparisons.map((comparison: StoreComparison) => new StoreComparison(comparison));
    this.comparisons = this.sortComparisons(comparisonClones);

    const keyIndicator: KeyIndicatorType = this.form.get('keyIndicator').value;
    if (!this.axisBuilder) {
      this.axisBuilder = new ChartAxisBuilder(AxisMode.RELATIVE, keyIndicator);
    } else {
      this.axisBuilder.setKeyIndicator(keyIndicator);
      this.axisBuilder.resetBounds();
    }

    this.numberLineDatasets = this.comparisons.map((comparison: StoreComparison) => {
      if (comparison.isChartable()) {
        const dataset = comparison.getDataSet(true);
        this.axisBuilder.extend(dataset.minValue);
        this.axisBuilder.extend(dataset.maxValue);
        return dataset;
      } else {
        return null;
      }
    });

    const validDatasetCount = _.filter(this.numberLineDatasets, (dataSet: NumberLineDataset) => !!dataSet).length;
    if (validDatasetCount) {
      this.axisBuilder.build();
    }

    this.isLoading = false;
  }

  private initFromAppState(): void {
    selectDetailState(this.ngrxStore)
      .pipe(filter((state: DetailState) => !!state.store && (!this.hasStandardLicense || !!state.marketArea)))
      .pipe(take(1))
      .subscribe((state: DetailState) => {
        this.store = state.store;
        this.marketArea = state.marketArea;

        this.form.get('keyIndicator').setValue(this.userPreferences.primaryKeyIndicator);

        if (this.store.isActive()) {
          this.isLoading = true;
          this.subscribeToKeyIndicatorChanges();
          this.subscribeToDetailState();
        }
      });
  }

  private sortComparisons(comparisons: StoreComparison[]): StoreComparison[] {
    function sortByRegion(comparison: StoreComparison): number {
      switch (comparison.region) {
        case RegionType.DRIVE_TIME:
          return 2;
        case RegionType.MARKET_AREA:
          return 4;
        case RegionType.RING:
          return 1;
        case RegionType.TRADE_AREA:
          return 3;
        default:
          console.error("Unexpected RegionType", comparison.region);
          return 100;
      }
    }

    function sortByComparisonType(comparison: StoreComparison): number {
      switch (comparison.comparisonType) {
        case ComparisonType.ALL:
          return 1;
        case ComparisonType.BANNER:
          return 3;
        case ComparisonType.FIT_CATEGORY:
          return 2;
        default:
          console.error("Unexpected ComparisonType", comparison.comparisonType);
          return 100;
      }
    }

    const iteratees = [sortByRegion, 'ringMiles', 'driveTimeMinutes', sortByComparisonType];
    return _.orderBy(comparisons, iteratees);
  }

  private subscribeToDetailState(): void {
    this.addSubscription(
      selectDetailState(this.ngrxStore)
        .pipe(debounceTime(100))
        .subscribe((state: DetailState) => {
          this.volume = state.volume;
          this.comparisonRequests = [...state.customComparisonRequests];

          const comparisons: StoreComparison[] = _.filter(state.customComparisons, (comparison: StoreComparison) => {
            return !this.hasStandardLicense || comparison.keyIndicator === this.form.get('keyIndicator').value;
          });

          // Don't draw until all are ready
          if (!!comparisons && comparisons.length > 0 && comparisons.length == this.comparisonRequests.length) {
            //Create new instances so we can adjust some properties
            this.applyComparisons(comparisons.map((comparison: StoreComparison) => new StoreComparison(comparison)));
          }
        })
    );
  }

  private subscribeToKeyIndicatorChanges(): void {
    this.addSubscription(
      this.form.get('keyIndicator').valueChanges
        .subscribe((value: KeyIndicatorType) => {
          this.isLoading = true;
          const updatedComparisons: StoreComparison[] = this.comparisons.map((comparison: StoreComparison) => {
            const copy = _.cloneDeep(comparison);
            copy.keyIndicator = value;
            return copy;
          });

          this.comparisonRequests = updatedComparisons;

          const updatedComparisonTasks: Observable<StoreComparison>[] = updatedComparisons.map((comparison: StoreComparison) => {
            return this.storeComparisonService.findOne(comparison);
          });

          forkJoin(updatedComparisonTasks)
            .subscribe((comparisons: StoreComparison[]) => {
              // Don't Draw until all are ready
              if (comparisons?.length === this.comparisonRequests?.length) {
                this.applyComparisons(comparisons);
              }
            });
        })
    );
  }
}
