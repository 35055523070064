import * as _ from 'lodash';
import { FutureStoreStatuses, HistoricalStoreStatuses } from '../../core/identity/constant/store-status-type.enum';
import { Feature } from '../../core/models';
import Icon = google.maps.Icon;
import Point = google.maps.Point;

export class MarkerIconUtil {

  static buildIcon(feature: Feature, isSelected: boolean): Icon {
    const url = this.buildIconUrl(feature, isSelected);

    return {
      anchor: new Point(20, 56),
      url: url
    };
  }

  private static buildIconUrl(feature: Feature, isSelected: boolean): string {
    if (feature.isStore()) {
      return this.buildStoreIconUrl(feature, isSelected);
    } else {
      throw new Error('NotImplementedException');
    }
  }

  private static buildStoreIconUrl(feature: Feature, isSelected: boolean): string {
    const isClosedStore = feature.isStore() && _.includes(HistoricalStoreStatuses, feature.getStore().getCurrentStatus()?.getStatusSystemName());
    const isFutureStore = feature.isStore() && _.includes(FutureStoreStatuses, feature.getStore().getCurrentStatus()?.getStatusSystemName());

    let markerColor = '263238';
    let markerOutlineColor = 'ffb300';
    if (isClosedStore) {
      markerOutlineColor = 'white';
      markerColor = '5c7b8a';
    } else if (isFutureStore) {
      markerColor = '005a87';
      markerOutlineColor = '4dc3ff';
    }

    if (isSelected) {
      markerColor = 'green';
      markerOutlineColor = 'limegreen';
    }

    const markerTransformation = `/c_scale,h_50/e_replace_color:${markerColor}:100:00ff00`;
    const outlineTransformation = `/e_outline:3,co_${markerOutlineColor}`;

    let imageFilename = feature.getStore().banner?.markerCloudinaryFilename;
    let imageHeight = 16;
    let imageOffset = 14;

    let imageBackgroundOverlayTransformation = `/l_Map%20Markers:circle-solid,g_north,c_limit,h_26,y_9,e_replace_color:white:100:00ff00,fl_layer_apply`;

    if (!imageFilename) {
      imageFilename = 'insights:preloader-icon';
      imageHeight = 16;
      imageOffset = 14;
    }

    const imageOverlayTransformation = `/l_${imageFilename},g_north,c_limit,h_${imageHeight},y_${imageOffset}`;

    return `https://res.cloudinary.com/mtn-retail-advisors/image/upload` +
      `${markerTransformation}` +
      `${outlineTransformation}` +
      `${imageBackgroundOverlayTransformation}` +
      `${imageOverlayTransformation}` +
      `/v1571263789/Map%20Markers/marker.png`;
  }
}
