import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { SpinnerSize } from '../../../core/util/spinner/spinner-size.enum';
import { MatTableDataSource } from '@angular/material/table';
import { UserProfile } from '../../../core/user-profile/user-profile';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SpinnerComponent } from '../../../core/util/spinner/spinner.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app-state';
import { WizardRunnerService } from '../../../core/wizard/wizard-runner.service';
import { BaseComponent } from '../../../core/base-component';
import { UserProfileService } from '../../../core/user-profile/user-profile.service';
import { debounceTime, finalize } from 'rxjs/operators';
import { Pageable } from '../../../core/service/pageable';
import { SetUsersList } from '../../administration-actions';
import { AdminState, selectAdminState } from '../../administration-state';
import { CreateUserWizard } from '../../../core/auth/create-user-wizard/create-user-wizard';
import { DatePipe } from '@angular/common';
import { InsightsTimestampPipe } from '../../../core/date/insights-timestamp.pipe';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'mtn-users-administration',
  templateUrl: './users-administration.component.html',
  styleUrls: ['./users-administration.component.scss'],
  providers: [DatePipe, InsightsTimestampPipe]
})
export class UsersAdministrationComponent extends BaseComponent implements OnInit, AfterViewInit {

  currentPage: number;
  dataSource = new MatTableDataSource<UserProfile>();
  displayedColumns: string[] = ['name', 'email', 'company', 'lastLoginDate'];
  filterForm = new FormGroup({
    filter: new FormControl()
  });
  SpinnerSize = SpinnerSize;
  totalResults: number;

  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  @ViewChild(MatSort)
  sort: MatSort;
  @ViewChild("usersSpinner")
  spinner: SpinnerComponent;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private store: Store<AppState>,
              private userService: UserProfileService,
              private wizardRunner: WizardRunnerService) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToAdminState();
    this.subscribeToFilterChanges();
    this.loadUsers();
  }

  ngAfterViewInit() {
    this.configureTable();
  }

  navigateToUserDetails(user: UserProfile): void {
    this.router.navigate([user.uuid], {relativeTo: this.activatedRoute});
  }

  openCreateUserWizard(): void {
    this.wizardRunner.run(new CreateUserWizard())
      .afterClosed().subscribe((result: CreateUserWizard) => {
      if (result.model.user) {
        this.navigateToUserDetails(result.model.user);
      }
    });
  }

  private configureTable(): void {
    this.sort.disableClear = true;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this.dataSource.sortingDataAccessor = (data: UserProfile, header: string): any => {
      if (header === 'name') {
        return data.name?.toLowerCase();
      } else if (header === 'email') {
        return data.email;
      } else if (header === 'company') {
        return data.company?.name;
      }

      return null;
    };

    this.dataSource.filterPredicate = (data: UserProfile, filter: string): boolean => {
      const lowerFilter = filter?.toLowerCase();

      return (data.name && data.name.toLowerCase().indexOf(lowerFilter) !== -1)
        || (data.email && data.email.toLowerCase().indexOf(lowerFilter) !== -1)
        || (data.company && data.company.name.toLowerCase().indexOf(lowerFilter) !== -1)
        || (data.company && data.company.uniqueIdentifier.toLowerCase().indexOf(lowerFilter) !== -1);
    };
  }

  private loadUsers(query: string = null): void {
    if (this.spinner) {
      this.spinner.start();
    }

    let params: HttpParams = null;
    if (query) {
      params = new HttpParams()
        .set('q', query);
    }

    this.userService.findAll(params)
      .pipe(finalize(() => this.spinner.stop()))
      .subscribe((results: Pageable<UserProfile>) => {
        this.store.dispatch(SetUsersList({users: results}));
      });
  }

  private subscribeToAdminState(): void {
    this.addSubscription(
      selectAdminState(this.store)
        .subscribe((state: AdminState) => {
          if (state.users) {
            this.dataSource.data = state.users.content;
            this.currentPage = state.users.number;
            this.totalResults = state.users.totalElements;
          }
        })
    );
  }

  private subscribeToFilterChanges(): void {
    this.addSubscription(
      this.filterForm.get('filter').valueChanges
        .pipe(debounceTime(300))
        .subscribe((value: string) => {
          this.dataSource.filter = value;
        })
    );
  }

}
