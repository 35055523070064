import { Component } from '@angular/core';
import { WizardPageComponent } from '../../../../core/wizard/wizard-page-component';
import { WelcomeWizard } from '../welcome-wizard';
import { Observable, of } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StoreVolume } from '../../../../core/store/volume/store-volume';
import { Space, Store } from '../../../../core/models';
import { SalesSqftDisplayType } from '../../../../core/user-preferences/sales-sqft-display-type.enum';
import { SalesVolumeDisplayType } from '../../../../core/user-preferences/sales-volume-display-type.enum';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { UserPreferencesService } from '../../../../core/user-preferences/user-preferences.service';

@Component({
  selector: 'mtn-welcome-wizard-volume-page',
  templateUrl: './welcome-wizard-volume-page.component.html',
  styleUrls: ['./welcome-wizard-volume-page.component.scss']
})
export class WelcomeWizardVolumePageComponent extends WizardPageComponent<WelcomeWizard> {

  key = 'volume';

  SalesSqftDisplayModeType = SalesSqftDisplayType;
  SalesVolumeDisplayModeType = SalesVolumeDisplayType;

  sampleVolume = new StoreVolume({
    salesArea: 50000,
    totalArea: 75000,
    volumeTotal: 300000
  });

  sampleStore = new Store({
    salesArea: 50000,
    space: new Space({
      area: 75000
    })
  });

  constructor(private userPreferencesService: UserPreferencesService) {
    super();
    this.title = 'Welcome! Let\'s get started.';
    this.stepperText = 'Sales Volume';
    this.backButtonText = 'Back';
    this.closeButtonText = 'Close';
    this.footerCheckboxText = 'Don\'t show again';
    this.headerText = 'Sales Volume Preferences';
    this.nextButtonText = 'Next';
  }

  onClose(): Observable<null> {
    if (this.wizard.isFooterCheckboxChecked) {
      const request = _.cloneDeep(this.wizard.model.userPreferences);
      request.isWelcomeWizardComplete = true;

      return this.userPreferencesService.updateOne(request)
        .pipe(map(() => null));
    } else {
      return super.onClose();
    }
  }

  onLoad(): Observable<any> {
    this.initForm();
    return super.onLoad();
  }

  onNext(): Observable<string> {
    const formValue = this.form.getRawValue();

    this.wizard.model.request = _.cloneDeep(this.wizard.model.userPreferences);
    this.wizard.model.request.salesVolumeDisplayMode = formValue.salesVolumeDisplayMode;
    this.wizard.model.request.salesSqftDisplayMode = formValue.salesSqftDisplayMode;

    return of('key-indicator');
  }

  private initForm(): void {
    this.form = new FormGroup({
      salesSqftDisplayMode: new FormControl(this.wizard.model.userPreferences.salesSqftDisplayMode, [Validators.required]),
      salesVolumeDisplayMode: new FormControl(this.wizard.model.userPreferences.salesVolumeDisplayMode, [Validators.required])
    });
  }

}
