import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Collection } from '../../../core/federation/collection/collection';
import { AuthorizationAwareComponent } from '../../../core/authorization-aware-component';
import { AppState } from '../../../app-state';
import { Store as NgrxStore } from '@ngrx/store';
import { FormControl, FormGroup } from '@angular/forms';
import { Feature, ShoppingCenter, Store } from '../../../core/models';
import { MtnMapOptions } from '../../../map/mtn-map-options';
import { MtnMap } from '../../../map/mtn-map';
import { FeatureType } from '../../../core/federation/feature/feature-type.enum';
import * as _ from 'lodash';
import { debounceTime, finalize, switchMap, tap } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { StoreVolume } from '../../../core/store/volume/store-volume';
import { formatAsCurrency } from '../../../core/util/string-utils';
import { Observable } from 'rxjs';
import { CollectionService } from '../../../core/federation/collection/collection.service';
import { CompanyCollection } from '../../../core/federation/collection/company-collection';
import { ToastService } from '../../../core/toast/toast.service';
import LatLngBounds = google.maps.LatLngBounds;
import LatLngLiteral = google.maps.LatLngLiteral;

@Component({
  selector: 'mtn-collection-feature-view',
  templateUrl: './collection-feature-view.component.html',
  styleUrls: ['./collection-feature-view.component.scss']
})
export class CollectionFeatureViewComponent extends AuthorizationAwareComponent implements OnChanges, OnInit {

  @Input()
  collection: Collection;
  @Input()
  features: Feature[];

  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  @ViewChild(MatSort)
  sort: MatSort;

  dataSource = new MatTableDataSource<Feature>();
  displayedColumns: string[];
  filteredFeatures: Feature[] = [];
  isCollectionEditor = false;
  isCollectionOwner = false;
  isMapViewEnabled = false;
  isRemovingFeatures = false;
  form = new FormGroup({
    filter: new FormControl(),
    viewMode: new FormControl(false)
  });
  map: MtnMap;
  mapOptions: MtnMapOptions;

  private _isDataSourceInitialized = false;

  constructor(private collectionService: CollectionService,
              protected ngrxStore: NgrxStore<AppState>,
              private toaster: ToastService) {
    super(ngrxStore);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.features?.currentValue?.length) {
      if (!this._isDataSourceInitialized) {
        this.initDataSource();
      }

      if (!this.mapOptions) {
        this.initMapOptions();
      }
    }
    if (this.currentUser) {
      this.setCollectionAuthorization();
    }
  }

  onAuthorizationChange(): void {
  }

  onAuthorizationInit(): void {
    this.setCollectionAuthorization();
    this.subscribeToFilterChanges();
    this.subscribeToViewModeChanges();
  }

  onMapReady(map: MtnMap): void {
    this.map = map;

    //Zoom and center to feature bounds
    const points = this.map.options.staticFeatures.map((feature: Feature) => feature.getGeometryAsLatLng());
    const bounds = new LatLngBounds();
    points.forEach((point: LatLngLiteral) => bounds.extend(point));
    this.map.fitBounds(bounds, 120);
  }

  removeFeature(feature: Feature): void {
    if (!this.isRemovingFeatures) {
      this.isRemovingFeatures = true;

      let task: Observable<any>;
      let undoTask: Observable<any>;
      /*
      If the feature is in our includedFeatures list, it was manually added to the collection, and we can simply remove it
      from the includedFeatures list.
       */
      const isFromIncludedFeaturesList = _.find(this.collection.includedFeatures, (uuid: string) => feature.id === uuid);
      if (isFromIncludedFeaturesList) {
        task = this.collectionService.deleteOneIncludedFeature(this.collection.uuid, feature.id)
          .pipe(tap(() => this.collection.includedFeatures = _.reject(this.collection.includedFeatures, (candidate: string) => candidate === feature.id)));
        undoTask = this.collectionService.addOneIncludedFeature(this.collection.uuid, feature.id)
          .pipe(tap(() => this.collection.includedFeatures.push(feature.id)));
      }
      //Else, it came from the FilterGroup, and must be added to the excludedFeatures list
      else {
        task = this.collectionService.addOneExcludedFeature(this.collection.uuid, feature.id)
          .pipe(tap(() => this.collection.excludedFeatures.push(feature.id)));
        undoTask = this.collectionService.deleteOneExcludedFeature(this.collection.uuid, feature.id)
          .pipe(tap(() => this.collection.excludedFeatures = _.reject(this.collection.excludedFeatures, feature.id)));
      }

      task
        .pipe(finalize(() => this.isRemovingFeatures = false))
        .subscribe(() => {
          this.features = _.reject(this.features, (candidate: Feature) => candidate.id === feature.id);
          this.dataSource.data = this.features;

          this.addSubscription(
            this.toaster.info('Successfully removed feature from collection', 5000, 'Undo')
              .onAction()
              .pipe(switchMap(() => undoTask))
              .subscribe(() => {
                this.features.push(feature);
                this.dataSource.data = this.features;
                this.toaster.info('Successfully added feature back to collection');
              })
          );
        });
    }
  }

  private initDataSource(): void {
    if (!this._isDataSourceInitialized) {
      this._isDataSourceInitialized = true;

      switch (this.collection.featureType) {
        case FeatureType.SHOPPING_CENTER:
          this.displayedColumns = ['name', 'owner', 'type', 'address', 'gla', 'actions'];
          break;
        case FeatureType.STORE:
          this.displayedColumns = ['name', 'address', 'fit', 'status', 'salesArea', 'totalArea', 'volume', 'salesSqft', 'actions'];
          break;
      }

      this.dataSource.data = this.features;

      setTimeout(() => {
        this.sort.disableClear = true;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }, 50);

      this.dataSource.filterPredicate = (feature: Feature, filter: string): boolean => {
        const lowerFilter = filter.toLowerCase();

        switch (this.collection.featureType) {
          case FeatureType.SHOPPING_CENTER:
            return this.shoppingCenterMatchesFilter(feature.getShoppingCenter(), lowerFilter);
          case FeatureType.STORE:
            return this.storeMatchesFilter(feature.getStore(), feature.getVolume(), lowerFilter);
        }
      };

      this.dataSource.sortingDataAccessor = (data: Feature, header: string): any => {
        switch (this.collection.featureType) {
          case FeatureType.SHOPPING_CENTER:
            const shoppingCenter = data.getShoppingCenter();

            switch (header) {
              case 'address':
                return shoppingCenter.location.addressLine1;
              case 'owner':
                return shoppingCenter.ownerCompany?.name;
              case 'name':
                return shoppingCenter.name;
              case 'type':
                return shoppingCenter.type;
              case 'gla':
                return shoppingCenter.grossLeasableArea;
              default:
                // @ts-ignore
                return data.content[header];
            }
          case FeatureType.STORE:
            const store = data.getStore();
            const volume = data.getVolume();

            switch (header) {
              case 'address':
                return store.space.location.addressLine1;
              case 'banner':
                return store.banner?.name;
              case 'name':
                return store.getNameAndNumber();
              case 'fit':
                return store.fit;
              case 'status':
                return store.currentStatus.type.systemName;
              case 'salesArea':
                return store.grocerySalesArea;
              case 'totalArea':
                return store.totalArea;
              case 'volume':
                return volume?.total;
              case 'salesSqft':
                return volume?.salesSqft;
              default:
                // @ts-ignore
                return data.content[header];
            }
        }
      };
    }
  }

  private initMapOptions(): void {
    this.mapOptions = {
      controlConfiguration: {
        isMapTypeEnabled: true,
        isMapModeEnabled: true,
        isGoogleSearchEnabled: false,
        isFiltersEnabled: false,
        isStreetViewEnabled: true,
        isRecenterEnabled: true,
        isTiltAndRotateEnabled: true,
        isZoomEnabled: true
      },
      featureType: this.collection.featureType,
      isAutoSaveEnabled: false,
      isFeatureMap: false,
      isPointsOfInterestEnabled: false,
      key: 'collection-features',
      staticFeatures: [...this.features],
      gestureHandling: 'cooperative'
    };
  }

  private setCollectionAuthorization(): void {
    const companyCollection = _.find(this.collection.shares, (share: CompanyCollection) => share.companyUuid === this.currentCompany.uuid);

    this.isCollectionOwner = this.hasInternalLicense
      || this.isCompanyAdministrator()
      || this.collection.owner?.uuid === this.currentUser.uuid;
    this.isCollectionEditor = this.isCollectionOwner
      || companyCollection?.isEditable;
  }

  private shoppingCenterMatchesFilter(shoppingCenter: ShoppingCenter, filter: string): boolean {
    return shoppingCenter.name?.toLowerCase().indexOf(filter) !== -1
      || (shoppingCenter.ownerCompany && shoppingCenter.ownerCompany.name.toLowerCase().indexOf(filter) !== -1)
      || (shoppingCenter.type && shoppingCenter.type.toLowerCase().indexOf(filter) !== -1)
      || (shoppingCenter.grossLeasableArea && shoppingCenter.grossLeasableArea.toLocaleString().indexOf(filter) !== -1)
      || shoppingCenter.location.addressLine1.toLowerCase().indexOf(filter) !== -1
      || shoppingCenter.location.city.toLowerCase().indexOf(filter) !== -1
      || shoppingCenter.location.state.toLowerCase().indexOf(filter) !== -1
      || shoppingCenter.location.postalCode.toLowerCase().indexOf(filter) !== -1;
  }

  private storeMatchesFilter(store: Store, volume: StoreVolume, filter: string): boolean {
    return store.getNameAndNumber().toLowerCase().indexOf(filter) !== -1
      || (store.banner && store.banner.name.toLowerCase().indexOf(filter) !== -1)
      || (store.fit && store.fit.toLowerCase().indexOf(filter) !== -1)
      || (store.getCurrentStatus() && store.getCurrentStatus().getStatusSystemName() && store.getCurrentStatus().getStatusSystemName().toLowerCase().indexOf(filter) !== -1)
      || (store.grocerySalesArea && store.grocerySalesArea.toLocaleString().indexOf(filter) !== -1)
      || (store.totalArea && store.totalArea.toLocaleString().indexOf(filter) !== -1)
      || (volume && volume.total && formatAsCurrency(volume.total).indexOf(filter) !== -1)
      || (volume && volume.salesSqft && formatAsCurrency(volume.salesSqft).indexOf(filter) !== -1)
      || store.space.location.addressLine1.toLowerCase().indexOf(filter) !== -1
      || store.space.location.city.toLowerCase().indexOf(filter) !== -1
      || store.space.location.state.toLowerCase().indexOf(filter) !== -1
      || store.space.location.postalCode.toLowerCase().indexOf(filter) !== -1;
  }

  private subscribeToFilterChanges(): void {
    this.addSubscription(
      this.form.get('filter').valueChanges
        .pipe(debounceTime(300))
        .subscribe((filter: string) => {
          this.dataSource.filter = filter;

          let filteredFeatures: Feature[];

          if (filter) {
            const lowerFilter = filter.toLowerCase();

            switch (this.collection.featureType) {
              case FeatureType.SHOPPING_CENTER:
                filteredFeatures = _.filter(this.features, (feature: Feature) => {
                  return this.shoppingCenterMatchesFilter(feature.getShoppingCenter(), lowerFilter);
                });
                break;
              case FeatureType.STORE:
                filteredFeatures = _.filter(this.features, (feature: Feature) => {
                  return this.storeMatchesFilter(feature.getStore(), feature.getVolume(), lowerFilter);
                })
                break;
            }
          } else {
            filteredFeatures = [...this.features];
          }

          this.filteredFeatures = filteredFeatures;

          if (this.map) {
            this.map.setOptions({
              staticFeatures: filteredFeatures
            });
          }
        })
    );
  }

  private subscribeToViewModeChanges(): void {
    this.addSubscription(
      this.form.get('viewMode').valueChanges
        .subscribe((value: boolean) => {
          this.isMapViewEnabled = value;
          if (this.isMapViewEnabled) {
            this.initMapOptions();
          }
        })
    );
  }
}
