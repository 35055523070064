<a class="hubspot-object-link mat-elevation-z1 color-help"
   [href]="company.hubspotId ? 'https://app.hubspot.com/contacts/20543635/company/' + company.hubspotId : 'mailto:dev@mtnra.com?subject=Insights%20Company%20Missing%20HubSpot%20ID'"
   target="_blank" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px"
   [ngClass]="{linked: !!company.hubspotId}" *ngIf="company">
  <span *ngIf="!company.hubspotId" class="color-muted">This company's HubSpot ID is missing. <br/>Contact <span
    class="color-help">dev@mntra.com</span> to have this resolved.</span>
  <img src="/assets/images/hubspot_logo.png" alt="HubSpot Logo"/>
</a>
<a class="hubspot-object-link mat-elevation-z1 color-help"
   [href]="contact.hubspotId ? 'https://app.hubspot.com/contacts/20543635/contact/' + contact.hubspotId : 'mailto:dev@mtnra.com?subject=Insights%20User%20Missing%20HubSpot%20ID'"
   target="_blank" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px"
   [ngClass]="{linked: !!contact.hubspotId}" *ngIf="contact">
  <span *ngIf="!contact.hubspotId" class="color-muted">This user's HubSpot ID is missing. <br/>Contact <span
    class="color-help">dev@mntra.com</span> to have this resolved.</span>
  <img src="/assets/images/hubspot_logo.png" alt="HubSpot Logo"/>
</a>
