import { Component, Input, OnInit } from '@angular/core';
import { MtnMap } from '../../../map/mtn-map';
import { MapEvent, MapEventType } from '../../../map/map-events';
import { FormControl, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { formatNumericalShorthand } from '../../../core/util/string-utils';
import { AuthorizationAwareComponent } from '../../../core/authorization-aware-component';
import { AppState } from '../../../app-state';
import { Store as NgrxStore } from '@ngrx/store';
import { FeatureType } from '../../../core/federation/feature/feature-type.enum';
import { Feature } from '../../../core/models';

@Component({
  selector: 'mtn-multi-select-spotlight-panel',
  templateUrl: './multi-select-spotlight-panel.component.html',
  styleUrls: ['../spotlight-panel.scss', './multi-select-spotlight-panel.component.scss']
})
export class MultiSelectSpotlightPanelComponent extends AuthorizationAwareComponent implements OnInit {

  @Input()
  map: MtnMap;

  filterForm = new FormGroup({
    filter: new FormControl()
  });
  layerLabel: string;
  sort = 'name';
  sortDirection: 'asc' | 'desc' = 'asc';
  storeUuids: string[] = [];

  features: Feature[] = [];

  constructor(protected ngrxStore: NgrxStore<AppState>) {
    super(ngrxStore);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onAuthorizationChange(): void {
  }

  onAuthorizationInit(): void {
    this.subscribeToMapSelectionEvents();
    this.subscribeToFilterChanges();
    this.updateFeatures();
    this.updateLayerLabel();
  }

  clearSelection(): void {
    this.map.setSelectionMode(null);
    this.map.select(null);
  }

  setSort(value: string, direction: 'asc' | 'desc'): void {
    this.sort = value;
    this.sortDirection = direction;
    this.updateFeatures();
  }

  private subscribeToFilterChanges(): void {
    this.addSubscription(
      this.filterForm.get('filter').valueChanges
        .subscribe(() => this.updateFeatures())
    );
  }

  private subscribeToMapSelectionEvents(): void {
    this.addSubscription(
      this.map.events
        .subscribe((event: MapEvent<any>) => {
          this.storeUuids = [...this.map.selections];
          switch (event.type) {
            case MapEventType.FILTER_CHANGE:
              this.updateLayerLabel();
              this.updateFeatures();
              break;
            case MapEventType.SELECTION_CHANGE:
              this.updateFeatures();
              break;
            default:
              break;
          }
        })
    );
  }

  private updateFeatures(): void {
    if (this.map.selections.size > 1) {
      let filterValue = this.filterForm.getRawValue().filter;
      if (filterValue) {
        filterValue = filterValue.toLowerCase().trim();
      }

      let rawFeatures = [...this.map.selectedFeatures];
      //Filter
      if (filterValue) {
        rawFeatures = _.filter(rawFeatures, (feature: Feature) => {
          let nameString = feature.getStore().name;
          if (feature.getStore().number) {
            nameString += ` (${feature.getStore().number})`;
          }

          const addressLine1 = feature.getStore().space.location.addressLine1;
          const principality = feature.getStore().space.location.getFormattedPrincipality();

          const sqftString = `${formatNumericalShorthand(feature.getStore().grocerySalesArea) || 'Unknown'} / ${formatNumericalShorthand(feature.getStore().totalArea) || 'Unknown'} sqft`;
          const volumeString = `$${formatNumericalShorthand(feature.getStore().volume?.total)} | ${feature.getStore().volume?.salesSqft}`;


          return nameString.toLowerCase().indexOf(filterValue) !== -1
            || addressLine1.toLowerCase().indexOf(filterValue) !== -1
            || principality.toLowerCase().indexOf(filterValue) !== -1
            || sqftString.toLowerCase().indexOf(filterValue) !== -1
            || volumeString.toLowerCase().indexOf(filterValue) !== -1;
        });
      }

      //Sort
      rawFeatures = _.orderBy(rawFeatures, (feature: Feature) => {
        switch (this.sort) {
          case 'name':
            let nameString = feature.getStore().name;
            if (feature.getStore().number) {
              nameString += `(${feature.getStore().number})`;
            }
            return nameString;
          case 'salesArea':
            return feature.getStore().grocerySalesArea || '';
          case 'totalArea':
            return feature.getStore().totalArea || '';
          case 'salesVolume':
            return feature.getStore().volume?.total || '';
          case 'salesSqft':
            return feature.getStore().volume?.salesSqft || '';
          default:
            return feature.getGeometryAsLatLng().lat;
        }
      }, this.sortDirection);

      this.features = rawFeatures;
    } else {
      this.features = [];
    }
  }

  private updateLayerLabel(): void {
    if (this.map.isFeatureType(FeatureType.SHOPPING_CENTER)) {
      this.layerLabel = 'Shopping Centers';
    } else if (this.map.isFeatureType(FeatureType.STORE)) {
      this.layerLabel = 'Stores';
    } else {
      this.layerLabel = 'Features';
    }
  }

}
