import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IsNotAuthenticatedGuard implements CanActivate {

  constructor(private authenticationService: AuthenticationService,
              private router: Router) {
  }

  canActivate(): Observable<boolean | UrlTree> {
    return this.authenticationService.isAuthenticated()
      .pipe(map((result: boolean) => {
        if (!result) {
          return true;
        } else {
          return this.router.parseUrl(environment.defaultLandingPage);
        }
      }));
  }

}
