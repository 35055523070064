import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AuthorizationAwareComponent } from '../../../core/authorization-aware-component';
import { MtnMap } from '../../mtn-map';
import { FilterToolbarState } from '../filter-toolbar-state';
import { FormControl, FormGroup } from '@angular/forms';
import { Store as NgrxStore } from '@ngrx/store';
import { AppState } from '../../../app-state';
import * as _ from 'lodash';
import { FilterType } from '../../../core/federation/filter/filter-type.enum';
import { CollectionFilter } from '../../../core/federation/filter/filter';
import { Collection } from '../../../core/federation/collection/collection';
import { UserProfileService } from '../../../core/user-profile/user-profile.service';
import { Pageable } from '../../../core/service/pageable';
import { finalize } from 'rxjs/operators';
import { EXCLUSIVE_FILTER_DEFINITION } from '../../../core/util/string-utils';

@Component({
  selector: 'mtn-collection-filter-assembly',
  templateUrl: './collection-filter-assembly.component.html',
  styleUrls: ['./collection-filter-assembly.component.scss']
})
export class CollectionFilterAssemblyComponent extends AuthorizationAwareComponent implements OnChanges, OnInit {

  @Input()
  map: MtnMap;
  @Input()
  toolbarState: FilterToolbarState;

  EXCLUSIVE_FILTER_DEFINITION = EXCLUSIVE_FILTER_DEFINITION;
  form: FormGroup;
  isLoading = false;
  options: Collection[] = [];

  constructor(private userProfileService: UserProfileService,
              protected ngrxStore: NgrxStore<AppState>) {
    super(ngrxStore);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.toolbarState && this.form) {
      this.updateForm();
    }
  }

  onAuthorizationChange(): void {
  }

  onAuthorizationInit(): void {
    this.initForm();
  }

  clear(): void {
    const formState = this.buildFormState(false);
    this.form.setValue(formState);
  }

  private buildFormState(value: boolean): any {
    const formValue = this.form.getRawValue();
    let formState = {
      isExclusive: !!formValue.isExclusive
    };
    this.options.forEach((option: Collection) => {
      // @ts-ignore
      formState[option.uuid] = value;
    });
    return formState;
  }

  private initForm(): void {
    this.isLoading = true;
    this.userProfileService.findOnesCollections(this.currentUser.uuid, null, true)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe((result: Pageable<Collection>) => {
        this.options = _.orderBy(result.content, 'name');

        let controls = {
          isExclusive: new FormControl()
        };

        result.content.forEach((collection: Collection) => {
          // @ts-ignore
          controls[collection.uuid] = new FormControl();
        });

        this.form = new FormGroup(controls);

        this.updateForm();
        this.subscribeToFormChanges();
      });
  }

  private subscribeToFormChanges(): void {
    this.addSubscription(
      this.form.valueChanges.subscribe((formValue: any) => {
        const filterGroup = _.cloneDeep(this.map.options.filterGroup);
        const isExclusive = formValue.isExclusive;

        const enabledUuids: string[] = [];

        this.options.forEach((option: Collection) => {
          if (formValue[option.uuid]) {
            enabledUuids.push(option.uuid);
          }
        });

        if (enabledUuids.length) {
          //If the map has a COLLECTION filter, we need to update it
          let filter = filterGroup.getOwnFilter(FilterType.COLLECTION);
          //Else, we need to add one
          if (!filter) {
            filter = new CollectionFilter();
          }

          filter.value = [...enabledUuids];
          filter.isExclusive = isExclusive;

          filterGroup.clearFilter(FilterType.COLLECTION);
          filterGroup.filters.push(filter);
          this.map.setOptions({
            filterGroup: filterGroup
          });
        } else {
          //If the map has a COLLECTION filter, we need to remove it
          if (filterGroup.hasOwnFilter(FilterType.COLLECTION)) {
            filterGroup.clearFilter(FilterType.COLLECTION);
            this.map.setOptions({
              filterGroup: filterGroup
            });
          }
          //Else, we don't need to do anything
        }
      })
    );
  }

  private updateForm(): void {
    const enabledUuids: string[] = this.toolbarState.filterCollections?.value;

    this.options.forEach((option: Collection) => {
      const isEnabled = _.includes(enabledUuids, option.uuid);
      this.form.get(option.uuid).setValue(isEnabled, {emitEvent: false});
    });

    this.form.get('isExclusive').setValue(this.toolbarState.filterCollections?.isExclusive, {emitEvent: false});
  }

}
