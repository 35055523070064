<div class="header-toolbar">
  <div class="toolbar" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
    <div class="clickable" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" [routerLink]="'/'">
      <img height="50px" width="46px" src="assets/images/mtn-logo.png" alt="MTN Logo"/>
      <div>
        <h1 class="mat-h1 text-small-caps no-bottom-margin">MTN Insights</h1>
        <div id="earlyAccessLabel" class="text-small-caps" [matTooltip]="earlyAccessTooltip">Early Access</div>
      </div>
    </div>
    <div fxFlex></div>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px" *ngIf="isAuthenticated; else notAuthenticated">
      <mtn-simple-alert *ngIf="!license?.suspendedDate && license?.isExpired()" title="License Expired"
                        text="Contact MTN to renew your license!" icon="exclamation-circle"
                        (onClick)="openLicenseExpiredWizard()"></mtn-simple-alert>
      <mtn-simple-alert *ngIf="license?.suspendedDate" title="License Suspended"
                        text="Contact MTN to reinstate your license!" icon="exclamation-circle" color="red"
                        (onClick)="openLicenseSuspendedWizard()"></mtn-simple-alert>
      <mtn-current-user-chip-and-menu></mtn-current-user-chip-and-menu>
    </div>
    <ng-template #notAuthenticated>
      <button mat-raised-button color="primary" *ngIf="!isAuthenticated" (click)="openAuth0SignInDialog()"
              mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.LOG_IN" fxHide.gt-sm>
        Sign In
      </button>
    </ng-template>
  </div>
  <div class="header-fader"></div>
</div>
