import { Component } from '@angular/core';
import { WizardPageComponent } from '../../../../core/wizard/wizard-page-component';
import { WelcomeWizard } from '../welcome-wizard';
import { Observable, of } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { KeyIndicatorType } from '../../../../core/user-preferences/key-indicator-type.enum';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { UserPreferencesService } from '../../../../core/user-preferences/user-preferences.service';

@Component({
  selector: 'mtn-welcome-wizard-key-indicator-page',
  templateUrl: './welcome-wizard-key-indicator-page.component.html',
  styleUrls: ['./welcome-wizard-key-indicator-page.component.scss']
})
export class WelcomeWizardKeyIndicatorPageComponent extends WizardPageComponent<WelcomeWizard> {

  key = 'key-indicator';

  KeyIndicatorType = KeyIndicatorType;

  constructor(private userPreferencesService: UserPreferencesService) {
    super();
    this.title = 'Welcome! Let\'s get started.';
    this.stepperText = 'Key Indicators';
    this.backButtonText = 'Back';
    this.closeButtonText = 'Close';
    this.footerCheckboxText = 'Don\'t show again';
    this.headerText = 'Key Indicator Preferences';
    this.nextButtonText = 'Next';
  }

  onClose(): Observable<null> {
    if (this.wizard.isFooterCheckboxChecked) {
      const request = _.cloneDeep(this.wizard.model.userPreferences);
      request.isWelcomeWizardComplete = true;

      return this.userPreferencesService.updateOne(request)
        .pipe(map(() => null));
    } else {
      return super.onClose();
    }
  }

  onLoad(): Observable<any> {
    this.initForm();
    return super.onLoad();
  }

  onNext(): Observable<string> {
    const formValue = this.form.getRawValue();

    this.wizard.model.request.primaryKeyIndicator = formValue.primaryKeyIndicator;

    return of('map');
  }

  private initForm(): void {
    this.form = new FormGroup({
      primaryKeyIndicator: new FormControl(this.wizard.model.userPreferences.primaryKeyIndicator, [Validators.required])
    });
  }

}
