import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AuthorizationAwareComponent } from '../../../../core/authorization-aware-component';
import { Store as NgrxStore } from '@ngrx/store';
import { AppState } from '../../../../app-state';
import { UserWithInvitation } from './user-with-invitation';
import { Company, CompanyInvitation, CompanyInvitationType } from '../../../../core/company/company';
import { CompanyService } from '../../../../core/company/company.service';
import { InviteUserToCompanyWizard } from './invite-user-to-company-wizard/invite-user-to-company-wizard';
import { AddUserToCompanyWizard } from './add-user-to-company-wizard/add-user-to-company-wizard';
import { WizardRunnerService } from '../../../../core/wizard/wizard-runner.service';
import { ToastService } from '../../../../core/toast/toast.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { forkJoin, Observable, of } from 'rxjs';
import { UserProfileService } from '../../../../core/user-profile/user-profile.service';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { UserProfile } from '../../../../core/user-profile/user-profile';
import { FormControl, FormGroup } from '@angular/forms';
import { ConfirmActionWizard } from '../../../../core/confirm-action-wizard/confirm-action-wizard';
import { CompanyInvitationService } from '../../../../core/company/company-invitation.service';
import * as _ from 'lodash';

@Component({
  selector: 'mtn-company-users-list',
  templateUrl: './company-users-list.component.html',
  styleUrls: ['./company-users-list.component.scss']
})
export class CompanyUsersListComponent extends AuthorizationAwareComponent implements OnInit {

  @Output()
  onUpdate = new EventEmitter<any>();

  @Input()
  company: Company;

  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  @ViewChild(MatSort)
  sort: MatSort;

  dataSource = new MatTableDataSource<UserWithInvitation>();
  displayedColumns: string[] = ['name', 'email', 'title', 'type', 'actions'];
  filterForm = new FormGroup({
    filter: new FormControl()
  });
  isActionsEnabled = false;
  isInviteOnly = false;

  private isLoading = false;

  constructor(private companyService: CompanyService,
              private companyInvitationService: CompanyInvitationService,
              protected ngrxStore: NgrxStore<AppState>,
              private toaster: ToastService,
              private userProfileService: UserProfileService,
              private wizardRunner: WizardRunnerService) {
    super(ngrxStore);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onAuthorizationChange(): void {
  }

  onAuthorizationInit(): void {
    this.isInviteOnly = window.location.href.indexOf('/administration') === -1;
    this.isActionsEnabled = !this.isInviteOnly || this.currentUser.isCompanyAdministrator;
    setTimeout(() => this.initTable(), 50);
    this.loadUsers();
  }

  handleAddUser(): void {
    if (this.isInviteOnly) {
      this.openInviteUserToCompanyWizard();
    } else {
      this.openAddUserToCompanyWizard();
    }
  }

  handleUpgradeDowngradeUser(userWithInvitation: UserWithInvitation): void {
    if (userWithInvitation.invitation) {
      if (userWithInvitation.isAdministrator) {
        this.openConfirmDowngradeInvitationWizard(userWithInvitation);
      } else {
        this.openConfirmUpgradeInvitationWizard(userWithInvitation);
      }
    } else {
      if (userWithInvitation.isAdministrator) {
        this.openConfirmDowngradeUserWizard(userWithInvitation);
      } else {
        this.openConfirmUpgradeUserWizard(userWithInvitation);
      }
    }
  }

  handleRemoveUser(userWithInvitation: UserWithInvitation): void {
    if (userWithInvitation.invitation) {
      this.openConfirmRevokeInvitationWizard(userWithInvitation);
    } else {
      this.openConfirmRemoveUserWizard(userWithInvitation);
    }
  }

  private initFilter(): void {
    this.addSubscription(
      this.filterForm.get('filter').valueChanges
        .subscribe((value: string) => {
          this.dataSource.filter = value;
        })
    );
  }

  private initTable(): void {
    this.sort.disableClear = true;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this.dataSource.sortingDataAccessor = (data: UserWithInvitation, header: string): any => {
      if (header === 'name') {
        return data.userProfile.getDisplayName().toLowerCase();
      } else if (header === 'email') {
        return data.userProfile.email.toLowerCase();
      } else if (header === 'title') {
        return data.userProfile.title?.toLowerCase();
      } else if (header === 'type') {
        return data.isAdministrator ? 'administrator' : 'member';
      }
      return null;
    };

    this.dataSource.filterPredicate = (data: UserWithInvitation, filter: string): boolean => {
      const lowerFilter = filter?.toLowerCase();

      const typeValue = data.isAdministrator ? 'administrator' : 'member';

      return (data.userProfile.getDisplayName().toLowerCase().indexOf(lowerFilter) !== -1)
        || (data.userProfile.email.toLowerCase().indexOf(lowerFilter) !== -1)
        || (data.userProfile.title && data.userProfile.title.toLowerCase().indexOf(lowerFilter) !== -1)
        || (typeValue.indexOf(lowerFilter) !== -1);
    };

    this.initFilter();
  }

  private loadUsers(): void {
    if (!this.isLoading) {
      this.isLoading = true;

      const tasks: Observable<UserWithInvitation[]>[] = [
        this.loadExistingUsers(),
      ];

      if (this.hasInternalLicense || this.currentUser.isCompanyAdministrator) {
        tasks.push(this.loadInvitedUsers());
      }

      forkJoin(tasks)
        .pipe(
          map((resultArrays: UserWithInvitation[][]) => {
            const compiledResults = [...resultArrays[0]];
            if (resultArrays.length > 1) {
              compiledResults.push.apply(compiledResults, resultArrays[1]);
            }
            return compiledResults;
          }),
          finalize(() => this.isLoading = false)
        )
        .subscribe((results: UserWithInvitation[]) => {
          this.dataSource.data = results;
        });
    }
  }

  private loadExistingUsers(): Observable<UserWithInvitation[]> {
    return this.companyService.findOne(this.company.uuid)
      .pipe(switchMap((company: Company) => {
        const tasks: Observable<any>[] = [];

        company.administrators.forEach((uuid: string) => {
          tasks.push(
            this.userProfileService.findOneCached(uuid)
              .pipe(map((result: UserProfile) => {
                return {
                  isAdministrator: true,
                  userProfile: result
                };
              }))
          );
        });

        company.members.forEach((uuid: string) => {
          tasks.push(
            this.userProfileService.findOneCached(uuid)
              .pipe(map((result: UserProfile) => {
                return {
                  isAdministrator: false,
                  userProfile: result
                };
              }))
          );
        });

        if (tasks.length) {
          return forkJoin(tasks);
        } else {
          return of([]);
        }
      }));
  }

  private loadInvitedUsers(): Observable<UserWithInvitation[]> {
    return this.companyService.findAllInvitations(this.company.uuid)
      .pipe(switchMap((invitations: CompanyInvitation[]) => {
        const userProfileTasks = invitations.map((invitation: CompanyInvitation) => {
          if (invitation.userProfileUuid) {
            return this.userProfileService.findOne(invitation.userProfileUuid)
              .pipe(map((userProfile: UserProfile) => {
                return {
                  invitation: invitation,
                  isAdministrator: invitation.type === CompanyInvitationType.ADMINISTRATOR,
                  userProfile: userProfile
                };
              }));
          } else {
            const userProfile = new UserProfile();
            userProfile.firstName = invitation.firstName;
            userProfile.lastName = invitation.lastName;
            userProfile.email = invitation.emailAddress;

            return of({
              invitation: invitation,
              isAdministrator: invitation.type === CompanyInvitationType.ADMINISTRATOR,
              userProfile: userProfile
            });
          }
        });

        if (userProfileTasks.length) {
          return forkJoin(userProfileTasks);
        } else {
          return of([]);
        }
      }));
  }

  private openAddUserToCompanyWizard(): void {
    const wizard = new AddUserToCompanyWizard();
    wizard.model = {
      company: this.company,
      isSearchEnabled: this.hasInternalLicense
    };

    this.wizardRunner.run(wizard)
      .afterClosed()
      .subscribe((result: AddUserToCompanyWizard) => {
        if (result?.model?.userProfile) {
          this.toaster.info('Successfully added user');
          this.onUpdate.emit();
          this.loadUsers();
        }
      });
  }

  private openConfirmDowngradeInvitationWizard(userWithInvitation: UserWithInvitation): void {
    const request = _.cloneDeep(userWithInvitation.invitation);
    request.type = CompanyInvitationType.MEMBER;

    const wizard = new ConfirmActionWizard();
    wizard.model = {
      onConfirm: this.companyInvitationService.updateOne(request)
        .pipe(tap(() => {
          this.toaster.info('Successfully downgraded user');
          this.onUpdate.emit();
          this.loadUsers();
        })),
      text: `Are you sure you want to downgrade ${userWithInvitation.userProfile.getDisplayName()} from an administrator to a member, removing all of their administrator privileges from ${this.company.name}?`,
      title: 'Downgrade User'
    };

    this.wizardRunner.run(wizard);
  }

  private openConfirmDowngradeUserWizard(userWithInvitation: UserWithInvitation): void {
    const wizard = new ConfirmActionWizard();
    wizard.model = {
      onConfirm: this.companyService.addOneMember(this.company.uuid, userWithInvitation.userProfile.uuid)
        .pipe(tap(() => {
          this.toaster.info('Successfully downgraded user');
          this.onUpdate.emit();
          this.loadUsers();
        })),
      text: `Are you sure you want to downgrade ${userWithInvitation.userProfile.getDisplayName()} from an administrator to a member, removing all of their administrator privileges from ${this.company.name}?`,
      title: 'Downgrade User'
    };

    this.wizardRunner.run(wizard);
  }

  private openConfirmRemoveUserWizard(userWithInvitation: UserWithInvitation): void {
    let task: Observable<any>;
    if (userWithInvitation.isAdministrator) {
      task = this.companyService.removeOneAdministrator(this.company.uuid, userWithInvitation.userProfile.uuid);
    } else {
      task = this.companyService.removeOneMember(this.company.uuid, userWithInvitation.userProfile.uuid);
    }

    const wizard = new ConfirmActionWizard();
    wizard.model = {
      onConfirm: task
        .pipe(tap(() => {
          this.toaster.info(`Successfully removed ${userWithInvitation.isAdministrator ? 'adminstrator' : 'member'}`);
          this.onUpdate.emit();
          this.loadUsers();
        })),
      text: `Are you sure you want to remove ${userWithInvitation.userProfile.getDisplayName()} as ${userWithInvitation.isAdministrator ? 'an administrator' : 'a member'} from ${this.company.name}?`,
      title: `Remove ${userWithInvitation.isAdministrator ? 'Administrator' : 'Member'}`
    };

    this.wizardRunner.run(wizard);
  }

  private openConfirmRevokeInvitationWizard(userWithInvitation: UserWithInvitation): void {
    const wizard = new ConfirmActionWizard();
    wizard.model = {
      onConfirm: this.companyInvitationService.expireOne(userWithInvitation.invitation.uuid)
        .pipe(tap(() => {
          this.toaster.info('Successfully revoked invitation');
          this.onUpdate.emit();
          this.loadUsers();
        })),
      text: `Are you sure you want to revoke ${userWithInvitation.userProfile.getDisplayName()}'s invitation?`,
      title: 'Revoke Invitation'
    };

    this.wizardRunner.run(wizard);
  }

  private openConfirmUpgradeInvitationWizard(userWithInvitation: UserWithInvitation): void {
    const request = _.cloneDeep(userWithInvitation.invitation);
    request.type = CompanyInvitationType.ADMINISTRATOR;

    const wizard = new ConfirmActionWizard();
    wizard.model = {
      onConfirm: this.companyInvitationService.updateOne(request)
        .pipe(tap(() => {
          this.toaster.info('Successfully upgraded user');
          this.onUpdate.emit();
          this.loadUsers();
        })),
      text: `Are you sure you want to upgrade ${userWithInvitation.userProfile.getDisplayName()} from a member to an administrator, granting administrator privileges for ${this.company.name}?`,
      title: 'Upgrade User'
    };

    this.wizardRunner.run(wizard);
  }

  private openConfirmUpgradeUserWizard(userWithInvitation: UserWithInvitation): void {
    const wizard = new ConfirmActionWizard();
    wizard.model = {
      onConfirm: this.companyService.addOneAdministrator(this.company.uuid, userWithInvitation.userProfile.uuid)
        .pipe(tap(() => {
          this.toaster.info('Successfully upgraded user');
          this.onUpdate.emit();
          this.loadUsers();
        })),
      text: `Are you sure you want to upgrade ${userWithInvitation.userProfile.getDisplayName()} from a member to an administrator, granting administrator privileges for ${this.company.name}?`,
      title: 'Upgrade User'
    };

    this.wizardRunner.run(wizard);
  }

  private openInviteUserToCompanyWizard(): void {
    const wizard = new InviteUserToCompanyWizard();
    wizard.model = {
      company: this.company
    };

    this.wizardRunner.run(wizard)
      .afterClosed()
      .subscribe((result: InviteUserToCompanyWizard) => {
        if (result.model.invitation) {
          this.onUpdate.emit();
          this.loadUsers();
        }
      });
  }

}
