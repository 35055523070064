import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app-state';
import { BaseComponent } from '../../../core/base-component';
import { SetCompaniesList } from '../../administration-actions';
import { Pageable } from '../../../core/service/pageable';
import { AdminState, selectAdminState } from '../../administration-state';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { FormControl, FormGroup } from '@angular/forms';
import { WizardRunnerService } from '../../../core/wizard/wizard-runner.service';
import { SpinnerSize } from '../../../core/util/spinner/spinner-size.enum';
import { SpinnerComponent } from '../../../core/util/spinner/spinner.component';
import { debounceTime, finalize, map, switchMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from '../../../core/company/company.service';
import { Company } from '../../../core/company/company';
import { DatePipe } from '@angular/common';
import { InsightsDatePipe } from '../../../core/date/insights-date.pipe';
import { AnalyticsEventType } from '../../../google-analytics/analytics-event-type';
import { CreateCompanyWizard } from './create-company-wizard/create-company-wizard';
import { DefinitionService } from '../../../core/definition/definition.service';
import { Definition } from '../../../core/definition/definition';
import { getLicenseDisplayName, LicenseType } from '../../../auth/authorization/license-type.enum';
import * as _ from 'lodash';

@Component({
  selector: 'mtn-companies-administration',
  templateUrl: './companies-administration.component.html',
  styleUrls: ['./companies-administration.component.scss'],
  providers: [DatePipe, InsightsDatePipe]
})
export class CompaniesAdministrationComponent extends BaseComponent implements OnInit, AfterViewInit {

  AnalyticsEventType = AnalyticsEventType;
  currentPage: number;
  dataSource = new MatTableDataSource<Company>();
  displayedColumns: string[] = ['name', 'identifier', 'license', 'licenseExpiration', 'licenseStatus'];
  filterForm = new FormGroup({
    filter: new FormControl()
  });
  SpinnerSize = SpinnerSize;
  totalResults: number;

  getLicenseDisplayName = getLicenseDisplayName;

  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  @ViewChild(MatSort)
  sort: MatSort;
  @ViewChild("companiesSpinner")
  spinner: SpinnerComponent;

  constructor(private activatedRoute: ActivatedRoute,
              private datePipe: InsightsDatePipe,
              private definitionService: DefinitionService,
              private router: Router,
              private store: Store<AppState>,
              private companyService: CompanyService,
              private wizardRunner: WizardRunnerService) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToAdminState();
    this.subscribeToFilterChanges();
    this.loadCompanies();
  }

  ngAfterViewInit() {
    this.configureTable();
  }

  navigateToCompanyDetails(company: Company): void {
    this.router.navigate([company.uuid], {relativeTo: this.activatedRoute});
  }

  openCreateCompanyWizard(): void {
    this.wizardRunner.run(new CreateCompanyWizard())
      .afterClosed().subscribe((result: CreateCompanyWizard) => {
      if (result.model.company) {
        this.navigateToCompanyDetails(result.model.company);
      }
    });
  }

  private configureTable(): void {
    this.sort.disableClear = true;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this.dataSource.sortingDataAccessor = (data: Company, header: string): any => {
      if (header === 'name') {
        return data.name?.toLowerCase();
      } else if (header === 'identifier') {
        return data.uniqueIdentifier;
      } else if (header === 'license') {
        return data.license.definition?.displayName.toLowerCase();
      } else if (header === 'licenseExpiration') {
        return data.license.expirationDate ? this.datePipe.transform(data.license.expirationDate) : null;
      } else if (header === 'licenseStatus') {
        if (data.license.isValid()) {
          return 'active';
        } else if (data.license.isExpired()) {
          return 'expired';
        }
      }
      return null;
    };

    this.dataSource.filterPredicate = (data: Company, filter: string): boolean => {
      const lowerFilter = filter?.toLowerCase();
      const expirationDateValue = data.license.expirationDate ? this.datePipe.transform(data.license.expirationDate) : null;

      let statusValue: string;
      if (data.license.isValid()) {
        statusValue = 'active';
      } else if (data.license.isExpired()) {
        statusValue = 'expired';
      }

      return (data.name && data.name.toLowerCase().indexOf(lowerFilter) !== -1)
        || (data.uniqueIdentifier && data.uniqueIdentifier.indexOf(lowerFilter) !== -1)
        || (data.license.type && data.license.definition?.displayName.toLowerCase().indexOf(lowerFilter) !== -1)
        || (expirationDateValue && expirationDateValue.indexOf(lowerFilter) !== -1)
        || (!expirationDateValue && 'non-expiring'.indexOf(lowerFilter) !== -1)
        || (statusValue && statusValue.indexOf(lowerFilter) !== -1);
    };
  }

  private loadCompanies(): void {
    if (this.spinner) {
      this.spinner.start();
    }

    this.definitionService.findAllLicenseTypes()
      .pipe(
        switchMap((definitions: Definition<LicenseType>[]) => {
          return this.companyService.findAll()
            .pipe(map((results: Pageable<Company>) => {
              results.content.forEach((company: Company) => {
                company.license.definition = _.find(definitions, (definition: Definition<LicenseType>) => definition.systemName === company.license.type);
              });

              return results;
            }));
        }),
        finalize(() => this.spinner.stop())
      ).subscribe((results: Pageable<Company>) => {
      this.store.dispatch(SetCompaniesList({companies: results}));
    });

  }

  private subscribeToAdminState(): void {
    this.addSubscription(
      selectAdminState(this.store)
        .subscribe((state: AdminState) => {
          if (state.companies) {
            this.dataSource.data = state.companies.content;
            this.currentPage = state.companies.number;
            this.totalResults = state.companies.totalElements;
          }
        })
    );
  }

  private subscribeToFilterChanges(): void {
    this.addSubscription(
      this.filterForm.get('filter').valueChanges
        .pipe(debounceTime(300))
        .subscribe((value: string) => {
          this.dataSource.filter = value;
        })
    );
  }

}
