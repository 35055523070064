import { BasicEntity } from '../basic-entity';
import { AddOnType } from './add-on-type.enum';
import { Definition } from '../definition/definition';

export class AddOn extends BasicEntity {

  //Private to mtn-insights-ui
  typeDefinition: Definition<AddOnType>;

  isHidden: boolean;
  type: AddOnType;

  constructor(raw?: any) {
    super();
    if (raw) {
      Object.assign(this, raw);
      if (raw.type) {
        // @ts-ignore
        this.type = AddOnType[<string>raw.type];
      }
    }
  }

}
