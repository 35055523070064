import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ComparisonType } from '../../../store/ranking/comparison-type.enum';

@Component({
  selector: 'mtn-comparison-type-field',
  templateUrl: './comparison-type-field.component.html',
  styleUrls: ['./comparison-type-field.component.scss']
})
export class ComparisonTypeFieldComponent {

  @Input()
  form: FormGroup;
  @Input()
  controlName: string = 'comparisonType';
  @Input()
  placeholder: string = 'In Group';

  ComparisonType = ComparisonType;

  constructor() {
  }

}
