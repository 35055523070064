import { Component, ViewChild } from '@angular/core';
import { WizardPageComponent } from '../../../../../../core/wizard/wizard-page-component';
import { CompetitiveIncursionsWizard } from '../competitive-incursions-wizard';
import { Observable } from 'rxjs';
import { AppState } from '../../../../../../app-state';
import { Store as NgrxStore } from '@ngrx/store';
import { selectUserState, UserState } from '../../../../../../auth/user-state';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { MtnMapOptions } from '../../../../../../map/mtn-map-options';
import { UserPreferences } from '../../../../../../core/user-preferences/user-preferences';
import { buildDefaultMapSnapshot } from '../../../../../../map/map-snapshot';
import { DistanceMatrixService } from '../../../../../../core/depot/distance-matrix.service';
import { Feature, Store } from '../../../../../../core/models';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import * as _ from 'lodash';
import { MapComponent } from '../../../../../../map/map.component';
import { formatSecondsAsDuration } from '../../../../../../core/util/string-utils';
import { metersToMiles } from '../../../../../../core/util/math-utils';
import { DistanceMatrixLocation, DistanceMatrixRequest } from '../../../../../../core/depot/distance-matrix-request';
import { DistanceMatrixResponse } from '../../../../../../core/depot/distance-matrix-response';
import { MtnMap } from '../../../../../../map/mtn-map';
import { MapEvent, MapEventType } from '../../../../../../map/map-events';

@Component({
  selector: 'mtn-competitive-incursions-page',
  templateUrl: './competitive-incursions-page.component.html',
  styleUrls: ['./competitive-incursions-page.component.scss']
})
export class CompetitiveIncursionsPageComponent extends WizardPageComponent<CompetitiveIncursionsWizard> {

  key = 'competitive-incursions';

  dataSource = new MatTableDataSource<StoreTravelTimeRecord>();
  displayedColumns: string[] = ['name', 'link', 'travelTime'];
  formatSecondsAsDuration = formatSecondsAsDuration;
  map: MtnMap;
  mapOptions: MtnMapOptions;
  metersToMiles = metersToMiles;

  @ViewChild('map')
  mapComponent: MapComponent;
  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  @ViewChild(MatSort)
  sort: MatSort;

  constructor(private distanceMatrixService: DistanceMatrixService,
              private ngrxStore: NgrxStore<AppState>) {
    super();
    this.title = 'Competitive Incursions';
    this.closeButtonText = 'Close';
  }

  onLoad(): Observable<any> {
    return selectUserState(this.ngrxStore)
      .pipe(
        take(1),
        switchMap((state: UserState) => {
          this.initMapOptions(state.userPreferences);

          const request = this.buildDistanceMatrixRequest();

          return this.distanceMatrixService.findOne(request)
            .pipe(tap((result: DistanceMatrixResponse) => {
              this.initTable();

              this.dataSource.data = this.wizard.model.incursions.map((incursion: Feature) => {
                const location = _.find(result.results[0].destinations, (destination: DistanceMatrixLocation) => destination.id === incursion.id);

                const record: StoreTravelTimeRecord = {
                  store: incursion.getStore(),
                  travelTime: location.travelTimeSeconds
                };

                return record;
              });
            }));
        }));
  }

  handleMapReady(map: MtnMap): void {
    this.map = map;

    this.map.setZoom(10);
    this.map.select(this.wizard.model.subjectStore.id);

    this.map.events
      .pipe(
        filter((event: MapEvent<any>) => event.type === MapEventType.MAP_DRAW),
        take(1)
      )
      .subscribe(() => {
        this.mapComponent.focusOnMarkers();
      });
  }

  private buildDistanceMatrixRequest(): DistanceMatrixRequest {
    const origin = DistanceMatrixLocation.fromStoreFeature(this.wizard.model.subjectStore);

    const request = new DistanceMatrixRequest();
    request.origins = [origin];
    request.destinations = this.wizard.model.incursions.map((incursion: Feature) => DistanceMatrixLocation.fromStoreFeature(incursion));
    return request;
  }

  private initMapOptions(userPreferences: UserPreferences): void {
    const snapshot = buildDefaultMapSnapshot(userPreferences.mapDefaultMode, userPreferences.mapDefaultType);
    const options = snapshot.options;
    options.key = 'competitive-incursions-map-2';
    options.center = this.wizard.model.subjectStore.getGeometryAsLatLng();
    options.controlConfiguration.isFiltersEnabled = false;
    options.controlConfiguration.isGoogleSearchEnabled = false;
    options.controlConfiguration.isStreetViewEnabled = false;
    options.controlConfiguration.isTiltAndRotateEnabled = false;
    options.isAutoSaveEnabled = false;
    options.isAutoUpdateEnabled = false;
    options.isFeatureMap = false;
    options.staticFeatures = [this.wizard.model.subjectStore, ...this.wizard.model.incursions];

    this.mapOptions = options;
  }

  private initTable(): void {
    setTimeout(() => {
      this.sort.disableClear = true;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, 50);

    this.dataSource.sortingDataAccessor = (data: StoreTravelTimeRecord, header: string): any => {
      if (header === 'name') {
        return data.store.name;
      } else if (header === 'travelTime') {
        return data.travelTime;
      }
      return null;
    };
  }

}

export interface StoreTravelTimeRecord {
  store: Store;
  travelTime: number;
}
