import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { InsightsTimestampPipe } from '../../../core/date/insights-timestamp.pipe';
import { InsightsDatePipe } from '../../../core/date/insights-date.pipe';
import { Company } from '../../../core/company/company';
import { CompanyService } from '../../../core/company/company.service';
import { WizardRunnerService } from '../../../core/wizard/wizard-runner.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '../../../core/base-component';
import { AnalyticsEventType } from '../../../google-analytics/analytics-event-type';
import { ConfirmDeleteCompanyWizard } from './confirm-delete-company-wizard/confirm-delete-company-wizard';
import { EditCompanyWizard } from './edit-company-wizard/edit-company-wizard';
import { AppState } from '../../../app-state';
import { Store as NgrxStore } from '@ngrx/store';
import { SetNamedSubject } from '../../administration-actions';
import * as _ from 'lodash';
import { AdminState, selectAdminState } from '../../administration-state';

@Component({
  selector: 'mtn-company-administration',
  templateUrl: './company-administration.component.html',
  styleUrls: ['./company-administration.component.scss'],
  providers: [DatePipe, InsightsDatePipe, InsightsTimestampPipe]
})
export class CompanyAdministrationComponent extends BaseComponent implements OnInit {

  AnalyticsEventType = AnalyticsEventType;
  chipForm = new FormGroup({
    chip: new FormControl(null, [Validators.maxLength(64)])
  });
  company: Company;
  identifierTooltip = 'This is a unique identifier for each company, used to protect our clients\' privacy. This identifier is displayed in the upper-left corner of the user\'s screen next to their company avatar.';

  constructor(private activatedRoute: ActivatedRoute,
              private companyService: CompanyService,
              private ngrxStore: NgrxStore<AppState>,
              private router: Router,
              private wizardRunner: WizardRunnerService) {
    super();
  }

  ngOnInit(): void {
    this.loadCompany();
  }

  loadCompany(): void {
    this.activatedRoute.params
      .pipe(take(1))
      .subscribe((params: Params) => {
        this.companyService.findOne(params.uuid)
          .subscribe((company: Company) => {
            this.subscribeToAdminState();
            this.ngrxStore.dispatch(SetNamedSubject({subject: company}));
          });
      });
  }

  openConfirmDeleteCompanyWizard(): void {
    const wizard = new ConfirmDeleteCompanyWizard();
    wizard.model = {
      company: this.company
    };

    this.wizardRunner.run(wizard)
      .afterClosed().subscribe((result: ConfirmDeleteCompanyWizard) => {
      if (result.model.isDeleted) {
        this.router.navigate(['administration', 'company']);
      }
    });
  }

  openEditCompanyWizard(): void {
    const wizard = new EditCompanyWizard();
    wizard.model = {
      company: this.company
    };

    this.wizardRunner.run(wizard)
      .afterClosed().subscribe((result: EditCompanyWizard) => {
      if (result.model.isEdited) {
        const copy = _.cloneDeep(this.company);
        copy.name = result.model.company.name;
        copy.description = result.model.company.description;
        copy.version = result.model.company.version;

        this.ngrxStore.dispatch(SetNamedSubject({subject: copy}));
      }
    });
  }

  private subscribeToAdminState(): void {
    this.addSubscription(
      selectAdminState(this.ngrxStore)
        .subscribe((state: AdminState) => {
          if (state.namedSubject instanceof Company) {
            this.company = state.namedSubject;
          }
        })
    );
  }
}
