import { Component, OnInit } from '@angular/core';
import { BaseComponent } from './core/base-component';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import * as _ from 'lodash';
import { AuthenticationService } from './auth/authentication/authentication.service';
import { Store } from '@ngrx/store';
import { AppState } from './app-state';
import { selectUserState, UserState } from './auth/user-state';
import { AnalyticsService } from './google-analytics/analytics.service';
import { NavigationState, selectNavigationState } from './navigation/navigation-state';
import { WizardRunnerService } from './core/wizard/wizard-runner.service';
import { EmailVerificationWizard } from './core/email-verification/email-verification-wizard';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent implements OnInit {

  isAuthenticated = false;
  isNavigationCollapsed = true;
  isNoPadRoute = false;
  isOnMaintenancePage = false;

  private noPadRoutes: string[] = [
    '/map-search',
    '/detail',
    '/maintenance'
  ];

  constructor(private activatedRoute: ActivatedRoute,
              private analyticsService: AnalyticsService,
              private authenticationService: AuthenticationService,
              private router: Router,
              private ngrxStore: Store<AppState>,
              private wizardRunner: WizardRunnerService) {
    super();
  }

  ngOnInit() {
    this.isOnMaintenancePage = window.location.pathname === '/maintenance';

    this.analyticsService.init();
    this.subscribeToRouteChange();
    this.subscribeToAuthState();
    this.subscribeToNavigationState();
    this.loadAuthenticationFromStorage();
  }

  private handleEmailVerification(event: NavigationEnd): void {
    const containsEmailParam = event.url.indexOf('email=') !== -1;
    if (containsEmailParam) {
      //Remove all Auth0 query parameters
      this.router.navigate([], {relativeTo: this.activatedRoute, queryParams: {}});

      this.wizardRunner.run(new EmailVerificationWizard());
    }
  }

  private loadAuthenticationFromStorage(): void {
    this.authenticationService.loadAuthenticationFromStorage();
  }

  private subscribeToAuthState(): void {
    this.addSubscription(
      selectUserState(this.ngrxStore)
        .subscribe((state: UserState) => {
          this.isAuthenticated = !!state.currentUser;
        })
    );
  }

  private subscribeToNavigationState(): void {
    this.addSubscription(
      selectNavigationState(this.ngrxStore)
        .subscribe((state: NavigationState) => {
          this.isNavigationCollapsed = state.isCollapsed;
        })
    );
  }

  private subscribeToRouteChange(): void {
    this.addSubscription(
      this.router.events
        .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          this.isOnMaintenancePage = window.location.pathname === '/maintenance' || event.urlAfterRedirects === '/maintenance';
          this.handleEmailVerification(event);
          this.isNoPadRoute = !!_.find(this.noPadRoutes, (route: string) => {
            return event.url.indexOf(route) === 0;
          });
        })
    );
  }

}
