import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../app-state';
import { selectUserState, UserState } from '../auth/user-state';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { IsAuthenticatedGuard } from '../auth/authentication/is-authenticated.guard';
import { AuthenticationService } from '../auth/authentication/authentication.service';
import { LicenseType } from '../auth/authorization/license-type.enum';

@Injectable({
  providedIn: 'root'
})
export class IsAdministratorGuard extends IsAuthenticatedGuard implements CanActivate {

  constructor(protected authenticationService: AuthenticationService,
              protected router: Router,
              private store: Store<AppState>) {
    super(authenticationService, router);
  }

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return super.canActivate(next)
      .pipe(take(1))
      .pipe(switchMap((result: boolean | UrlTree) => {
        if (result && result instanceof UrlTree) {
          return of(result);
        } else if (result === true) {
          return selectUserState(this.store)
            .pipe(filter((state: UserState) => state && !!state.currentUser))
            .pipe(take(1))
            .pipe(map((state: UserState) => {
              return state.currentUser && state.currentUser.licenseType === LicenseType.INTERNAL;
            }));
        } else {
          return of(this.routeToPublicPage(next));
        }
      }));
  }

}
