import { Component } from '@angular/core';
import { WizardPageComponent } from '../../../core/wizard/wizard-page-component';
import { AddToCollectionWizard } from '../add-to-collection-wizard';
import { CollectionService } from '../../../core/federation/collection/collection.service';
import { ToastService } from '../../../core/toast/toast.service';
import { Observable, of } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, finalize, switchMap, tap } from 'rxjs/operators';
import { Pageable } from '../../../core/service/pageable';
import * as _ from 'lodash';
import { MatSelectionListChange } from '@angular/material/list';
import { Collection } from '../../../core/federation/collection/collection';
import { UserProfileService } from '../../../core/user-profile/user-profile.service';

@Component({
  selector: 'mtn-add-to-collection-page',
  templateUrl: './add-to-collection-page.component.html',
  styleUrls: ['./add-to-collection-page.component.scss']
})
export class AddToCollectionPageComponent extends WizardPageComponent<AddToCollectionWizard> {

  key = 'add-to-collection';

  collections: Collection[] = [];
  isLoading = false;

  constructor(private collectionService: CollectionService,
              private toaster: ToastService,
              private userProfileService: UserProfileService) {
    super();
    this.title = 'Add to Collection';
    this.nextButtonText = 'Submit';
    this.closeButtonText = 'Cancel';
  }

  onLoad(): Observable<any> {
    this.initForm();
    return this.loadCollections();
  }

  onNext(): Observable<string> {
    const selectedCollection = this.form.getRawValue().collection;
    return this.collectionService.findOne(selectedCollection.uuid)
      .pipe(switchMap((collection: Collection) => {
        return this.collectionService.addAllIncludedFeatures(collection.uuid, this.wizard.model.storeUuids)
          .pipe(switchMap(() => {
            this.toaster.info("Successfully updated collection");
            return of(null);
          }));
      }));
  }

  handleSelection(event: MatSelectionListChange): void {
    let collection: Collection;
    if (event.options.length === 1) {
      collection = event.options[0].value;
    } else {
      collection = null;
    }
    this.form.get('collection').setValue(collection);
  }

  private initForm(): void {
    this.form = new FormGroup({
      collection: new FormControl(null, [Validators.required]),
      filter: new FormControl(null)
    });

    this.addSubscription(
      this.form.get('filter').valueChanges
        .pipe(
          debounceTime(300),
          switchMap(() => this.loadCollections())
        )
        .subscribe()
    );
  }

  private loadCollections(): Observable<any> {
    if (!this.isLoading) {
      this.isLoading = true;
      const formValue = this.form.getRawValue();

      return this.userProfileService.findOnesCollections(this.wizard.model.userProfileUuid, formValue.filter, true)
        .pipe(
          tap((result: Pageable<Collection>) => {
            this.collections = _.orderBy(result.content, 'name');
          }),
          finalize(() => this.isLoading = false)
        );
    }
  }

}
