import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { BaseComponent } from '../../../core/base-component';
import { interval } from 'rxjs';
import { AuthenticationService } from '../../../auth/authentication/authentication.service';

@Component({
  selector: 'mtn-sign-up-sign-in-panel',
  templateUrl: './sign-up-sign-in-panel.component.html',
  styleUrls: ['./sign-up-sign-in-panel.component.scss']
})
export class SignUpSignInPanelComponent extends BaseComponent implements AfterViewInit {

  questions = [
    'How is my grocery store performing compared to the competition?',
    'Who is my customer, and how can I find more of them?',
    'There\'s another grocer opening near me. How are they going to impact my sales?',
    'I need a new anchor for my shopping center. Who should I attract?',
    'How is my grocery tenant performing?',
    'I\'m looking to expand. Where should I open a new grocery store?',
    'How do I know this shopping center is a good investment?'
  ];

  @ViewChild(MatTabGroup)
  tabGroup: MatTabGroup;

  tabIndex = 0;

  constructor(private authenticationService: AuthenticationService) {
    super();
  }

  ngAfterViewInit(): void {
    this.startCarousel();
  }

  handleSignIn(): void {
    this.authenticationService.openAuth0AuthenticationDialog();
  }

  private nextTab(): void {
    let tabIndex = this.tabIndex + 1;
    if (tabIndex === this.questions.length) {
      tabIndex = 0;
    }
    this.tabIndex = tabIndex;
  }

  private startCarousel(): void {
    this.addSubscription(
      interval(5000)
        .subscribe(() => {
          this.nextTab();
        })
    );
  }

}
